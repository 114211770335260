import React, { Fragment } from 'react';
import { dataFields } from '../../config/data-fields-config';
import DataEntry from '../data-components/data-entry';
import NumericDataEntry from '../data-components/numeric-data-entry';
import Verification from '../data-components/verification';
import withCopyEdit from '../data-container';
import { PatternDataEntry } from '../data-components/pattern-data-entry';
const locationNumberPattern = "[1-9]\\d*[a-z]?(-([1-9]\\d*))?"; ///[1-9]\d*[a-z]?(-([1-9]\d*))?/;
function generate_osm_link(osm_id, osm_type) {
    return "https://www.openstreetmap.org/" + osm_type + "/" + osm_id.padStart(9, "0");
}
function generate_wikidata_link(wikidata_id) {
    return "https://www.wikidata.org/wiki/" + wikidata_id;
}
const LocationView = (props) => (React.createElement(Fragment, null,
    React.createElement(DataEntry, { title: dataFields.location_name.title, slug: "location_name", value: props.building.location_name, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.location_name.tooltip, placeholder: "https://...", isUrl: true }),
    React.createElement(Verification, { slug: "location_name", allow_verify: props.user !== undefined && props.building.location_name !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("location_name"), user_verified_as: props.user_verified.location_name, verified_count: props.building.verified.location_name }),
    React.createElement(PatternDataEntry, { title: dataFields.location_number.title, slug: "location_number", value: props.building.location_number, pattern: locationNumberPattern, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.location_number.tooltip, disabled: true }),
    React.createElement(DataEntry, { title: dataFields.location_street.title, slug: "location_street", value: props.building.location_street, mode: props.mode, copy: props.copy, onChange: props.onChange, maxLength: 30, disabled: true }),
    React.createElement(DataEntry, { title: dataFields.location_town.title, slug: "location_town", value: props.building.location_town, mode: props.mode, copy: props.copy, onChange: props.onChange, disabled: true }),
    React.createElement(DataEntry, { title: dataFields.location_postcode.title, slug: "location_postcode", value: props.building.location_postcode, mode: props.mode, copy: props.copy, onChange: props.onChange, maxLength: 8, valueTransform: x => x.toUpperCase(), disabled: true }),
    React.createElement(DataEntry, { title: dataFields.ref_toid.title, slug: "ref_toid", value: props.building.ref_toid, mode: props.mode, copy: props.copy, tooltip: dataFields.ref_toid.tooltip, onChange: props.onChange, disabled: true }),
    React.createElement(DataEntry, { title: dataFields.ref_osm_id.title, slug: "ref_osm_id", value: props.building.ref_osm_id, mode: props.mode, copy: props.copy, tooltip: dataFields.ref_osm_id.tooltip, maxLength: 20, onChange: props.onChange, disabled: true }),
    React.createElement(DataEntry, { title: dataFields.ref_osm_type.title, slug: "ref_osm_type", value: props.building.ref_osm_type, mode: props.mode, copy: props.copy, tooltip: dataFields.ref_osm_type.tooltip, maxLength: 20, onChange: props.onChange, disabled: true }),
    (props.building.ref_osm_id !== null) ?
        React.createElement(React.Fragment, null,
            React.createElement("p", null,
                React.createElement("b", null, "gehe zu OpenStreetMap: ")),
            React.createElement("p", null,
                React.createElement("a", { href: generate_osm_link(props.building.ref_osm_id, props.building.ref_osm_type), target: "_blank" }, generate_osm_link(props.building.ref_osm_id, props.building.ref_osm_type))))
        :
            React.createElement(React.Fragment, null),
    React.createElement(DataEntry, { title: dataFields.ref_land_parcel.title, slug: "ref_land_parcel", value: props.building.ref_land_parcel, mode: props.mode, copy: props.copy, tooltip: dataFields.ref_land_parcel.tooltip, maxLength: 20, onChange: props.onChange, disabled: true }),
    React.createElement(DataEntry, { title: dataFields.ref_wikidata.title, slug: "ref_wikidata", value: props.building.ref_wikidata, mode: props.mode, copy: props.copy, tooltip: dataFields.ref_wikidata.tooltip, maxLength: 20, onChange: props.onChange }),
    React.createElement(Verification, { slug: "ref_wikidata", allow_verify: props.user !== undefined && props.building.ref_wikidata !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("ref_wikidata"), user_verified_as: props.user_verified.ref_wikidata, verified_count: props.building.verified.ref_wikidata }),
    (props.building.ref_wikidata !== null) ?
        React.createElement(React.Fragment, null,
            React.createElement("p", null,
                React.createElement("b", null, "gehe zu Wikidata: ")),
            React.createElement("p", null,
                React.createElement("a", { href: generate_wikidata_link(props.building.ref_wikidata), target: "_blank" }, generate_wikidata_link(props.building.ref_wikidata))))
        :
            React.createElement(React.Fragment, null),
    React.createElement(DataEntry, { title: dataFields.ref_wikipedia.title, slug: "ref_wikipedia", value: props.building.ref_wikipedia, mode: props.mode, copy: props.copy, tooltip: dataFields.ref_wikipedia.tooltip, maxLength: 256, onChange: props.onChange }),
    React.createElement(Verification, { slug: "ref_wikipedia", allow_verify: props.user !== undefined && props.building.ref_wikipedia !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("ref_wikipedia"), user_verified_as: props.user_verified.ref_wikipedia, verified_count: props.building.verified.ref_wikipedia }),
    (props.building.ref_wikipedia !== null) ?
        React.createElement(React.Fragment, null,
            React.createElement("p", null,
                React.createElement("b", null, "gehe zu Wikipedia: ")),
            React.createElement("p", null,
                React.createElement("a", { href: props.building.ref_wikipedia, target: "_blank" }, props.building.ref_wikipedia)))
        :
            React.createElement(React.Fragment, null),
    React.createElement(NumericDataEntry, { title: dataFields.location_latitude.title, slug: "location_latitude", value: props.building.location_latitude, mode: props.mode, copy: props.copy, step: 0.00001, min: -90, max: 90, placeholder: "geogr. Breitengrad, z.B. 51.049259", onChange: props.onChange, disabled: true }),
    React.createElement(NumericDataEntry, { title: dataFields.location_longitude.title, slug: "location_longitude", value: props.building.location_longitude, mode: props.mode, copy: props.copy, step: 0.00001, min: -180, max: 180, placeholder: "geogr. L\u00E4ngengrad, z.B. 13.73836", onChange: props.onChange, disabled: true })));
const LocationContainer = withCopyEdit(LocationView);
export default LocationContainer;
