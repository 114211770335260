import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './header.css';
import { Logo } from './components/logo';
import { WithSeparator } from './components/with-separator';
import { useAuth } from './auth-context';
let config = require('../cc-config.json');
function getCurrentMenuLinks(username) {
    return [
        [
            ...(username != undefined ?
                [
                    {
                        to: "/my-account.html",
                        text: `Account (${username})`
                    }
                ] :
                [
                    {
                        to: "/login.html",
                        text: "Anmelden"
                    },
                    {
                        to: "/sign-up.html",
                        text: "Registrieren"
                    }
                ])
        ],
        [
            {
                to: "/view/categories",
                text: "Karte anzeigen"
            },
            {
                to: "/edit/categories",
                text: "Karte bearbeiten"
            },
        ],
        [
            {
                to: "https://colouring.dresden.ioer.info",
                text: "Projektwebseite ",
                disabled: false,
                external: true
            },
            {
                to: "/data-extracts.html",
                text: "Download der Daten"
            },
            {
                to: config.githubURL,
                text: "Programmcode öffnen",
                external: true
            },
            /*             {
                            to: "/showcase.html",
                            text: "Case Study Showcase",
                            disabled: true,
                        }, */
        ],
        [
            /*             {
                            to: "https://pages.colouring.london",
                            text: "About",
                            external: true
                        },
                        {
                            to: "https://pages.colouring.london/buildingcategories",
                            text: "Data Categories",
                            external: true
                        },
                        {
                            to: "https://pages.colouring.london/whoisinvolved",
                            text: "Who's Involved?",
                            external: true
                        }, */
            /*             {
                            to: "https://pages.colouring.london/data-ethics",
                            text: "Datenethik",
                            external: true
                        }, */
            {
                to: "https://colouringcities.org/",
                text: "Colouring Cities Research Programme (CCRP)",
                external: true
            },
            {
                to: "https://github.com/colouring-cities/manual/wiki",
                text: "Manual / Wiki des CCRP",
                external: true
            },
        ],
        /*         [
                    {
                        to: "/leaderboard.html",
                        text: "Top Contributors"
                    },
                    {
                        to: "https://discuss.colouring.london",
                        text: "Discussion Forum",
                        external: true
                    },
                    {
                        to: "https://discuss.colouring.london/c/blog/9",
                        text: "Blog",
                        external: true
                    },
                ], */
        [
            {
                to: "/privacy-policy.html",
                text: "Datenschutz & Sicherheit"
            },
            {
                to: "/contributor-agreement.html",
                text: "Vereinbarung zur Mitwirkung"
            },
            {
                to: "/code-of-conduct.html",
                text: "Verhaltenskodex für Mitwirkende"
            },
            {
                to: "/data-accuracy.html",
                text: "Haftungsausschluss"
            },
            /*             {
                            to: "/ordnance-survey-uprn.html",
                            text: "Ordnance Survey terms of UPRN usage"
                        }, */
        ],
        [
            {
                to: "/contact.html",
                text: "Kontakt"
            },
            {
                to: "https://ioer.de/impressum",
                text: "Impressum",
                external: true
            },
            {
                to: "/declaration-on-accessibility.html",
                text: "Barrierefreiheit",
                external: true
            },
        ],
    ];
}
const Menu = ({ onNavigate }) => {
    const { user } = useAuth();
    const menuLinkSections = getCurrentMenuLinks(user === null || user === void 0 ? void 0 : user.username);
    return (React.createElement(WithSeparator, { separator: React.createElement("hr", null) }, menuLinkSections.map((section, idx) => React.createElement("ul", { key: `menu-section-${idx}`, className: "navbar-nav flex-container" }, section.map(item => (React.createElement("li", { className: 'nav-item', key: `${item.to}-${item.text}` }, item.disabled ?
        React.createElement(LinkStub, { note: item.note }, item.text) :
        item.external ?
            React.createElement(ExternalNavLink, { to: item.to }, item.text) :
            React.createElement(InternalNavLink, { to: item.to, onClick: onNavigate }, item.text))))))));
};
const InternalNavLink = ({ to, onClick, children }) => (React.createElement(NavLink, { className: "nav-link", to: to, onClick: onClick }, children));
const ExternalNavLink = ({ to, children }) => (React.createElement("a", { className: "nav-link", href: to }, children));
const LinkStub = ({ note, children }) => (React.createElement("a", { className: "nav-link disabled" },
    children,
    React.createElement("span", { className: "link-note" }, note)));
export const Header = ({ animateLogo }) => {
    const [collapseMenu, setCollapseMenu] = useState(true);
    const toggleCollapse = () => setCollapseMenu(!collapseMenu);
    const handleNavigate = () => setCollapseMenu(true);
    return (React.createElement("header", { className: "main-header navbar navbar-light" },
        React.createElement("div", { className: "nav-header" },
            React.createElement(NavLink, { to: "/" },
                React.createElement(Logo, { variant: animateLogo ? 'animated' : 'default' })),
            React.createElement("button", { className: "navbar-toggler", type: "button", onClick: toggleCollapse, "aria-expanded": !collapseMenu, "aria-label": "Toggle navigation" },
                "Men\u00FC\u00A0",
                collapseMenu ?
                    React.createElement("span", { className: "navbar-toggler-icon" })
                    : React.createElement("span", { className: "close" }, "\u00D7"))),
        React.createElement("nav", { className: collapseMenu ? 'collapse navbar-collapse' : 'navbar-collapse' },
            React.createElement(Menu, { onNavigate: handleNavigate }))));
};
