import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth-context';
import ErrorBox from '../components/error-box';
import { SpinnerIcon } from '../components/icons';
import InfoBox from '../components/info-box';
import SupporterLogos from '../components/supporter-logos';
let config = require('../../cc-config.json');
export const SignUp = () => {
    const { isLoading, signup } = useAuth();
    const [error, setError] = useState(undefined);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [confirmConditions, setConfirmConditions] = useState(false);
    const onSubmit = useCallback(e => {
        e.preventDefault();
        signup({ username, email, confirmEmail, password }, setError);
    }, [username, email, confirmEmail, password, confirmConditions, signup]);
    const msgName = `Willkommen bei Colouring ${config.cityName}.`;
    return (React.createElement("article", null,
        React.createElement("section", { className: "main-col" },
            React.createElement("h1", { className: "h2" }, "Kostenlos Registrieren"),
            React.createElement(InfoBox, { msg: msgName },
                React.createElement("br", null),
                "Feedback, Hinweise oder Fragen bitte per E-Mail an colouringdresden@ioer.de . Oder direkt ",
                React.createElement("a", { href: "https://github.com/colouring-cities/colouring-dresden/issues" }, "hier"),
                " berichten."),
            React.createElement("p", null,
                "Einen neuen Account anlegen. Let\u00B4s colour ",
                config.cityName,
                "!"),
            React.createElement(ErrorBox, { msg: error }),
            React.createElement(InfoBox, null,
                React.createElement("b", null, "Hinweise zum Benutzername:"),
                React.createElement("ul", null,
                    React.createElement("li", null, "da der Benutzername \u00F6ffentlich steht, bitte m\u00F6glichst keine eigenen Klarnamen verwenden"),
                    React.createElement("li", null, "f\u00FCr den Benutzernamen sind nur die folgenden Zeichen zul\u00E4ssig:"),
                    React.createElement("ul", null,
                        React.createElement("b", null,
                            React.createElement("li", null, "Buchstaben"),
                            React.createElement("li", null, "Zahlen"),
                            React.createElement("li", null, "Unterstrich"))))),
            React.createElement("form", { onSubmit: onSubmit },
                React.createElement("label", { htmlFor: "username" }, "Benutzername*"),
                React.createElement("input", { name: "username", id: "username", className: "form-control", type: "text", value: username, onChange: e => setUsername(e.target.value), placeholder: "Bitte beachten: der gew\u00E4hlte Benutzername ist \u00F6ffentlich", required: true, minLength: 4, maxLength: 29, pattern: "\\w+", title: "Der Benutzername darf nur Buchstaben, Zahlen sowie Unterstrich enthalten." }),
                React.createElement("label", { htmlFor: "email" }, "E-Mail (optional)"),
                React.createElement("input", { name: "email", id: "email", className: "form-control", type: "email", value: email, onChange: e => setEmail(e.target.value), placeholder: "person@beispiel.de" }),
                React.createElement(InfoBox, { msg: "Die Registrierung eines neuen Accounts soll m\u00F6glichst wenig Daten erfordern. Eine E-Mail Adresse wird nur zum Zur\u00FCcksetzen des Passworts (bei Passwort vergessen) ben\u00F6tigt und ist daher optional." }),
                React.createElement("label", { htmlFor: "confirm_email" }, "E-Mail best\u00E4tigen (optional)"),
                React.createElement("input", { name: "confirm_email", id: "confirm_email", className: "form-control", type: "email", value: confirmEmail, onChange: e => setConfirmEmail(e.target.value) }),
                React.createElement("label", { htmlFor: "password" }, "Passwort (mindestens 8 Zeichen)"),
                React.createElement("input", { name: "password", id: "password", className: "form-control", type: (showPassword) ? 'text' : 'password', value: password, onChange: e => setPassword(e.target.value), required: true, minLength: 8, maxLength: 128 }),
                React.createElement("div", { className: "form-check" },
                    React.createElement("input", { id: "show_password", name: "show_password", className: "form-check-input", type: "checkbox", checked: showPassword, onChange: e => setShowPassword(e.target.checked) }),
                    React.createElement("label", { className: "form-check-label", htmlFor: "show_password" }, "Passwort zeigen?")),
                React.createElement("div", { className: "form-check" },
                    React.createElement("input", { id: "confirm_conditions", name: "confirm_conditions", className: "form-check-input", type: "checkbox", checked: confirmConditions, onChange: e => setConfirmConditions(e.target.checked), required: true }),
                    React.createElement("label", { className: "form-check-label", htmlFor: "confirm_conditions" },
                        "Hiermit best\u00E4tige ich, dass ich die  ",
                        React.createElement(Link, { to: "/privacy-policy.html" }, "Datenschutzerkl\u00E4rung"),
                        ", die ",
                        React.createElement(Link, { to: "/contributor-agreement.html" }, "Vereinbarung zur Mitwirkung"),
                        ", den ",
                        React.createElement(Link, { to: "/data-accuracy.html" }, "Haftungsausschluss"),
                        " und den  ",
                        React.createElement(Link, { to: "/code-of-conduct.html" }, "Verhaltenskodex zur Mitwirkung"),
                        " gelesen und verstanden habe und stimme diesen zu.")),
                React.createElement("div", { className: "buttons-container with-space" },
                    React.createElement("input", { type: "submit", disabled: isLoading, value: "Kostenlos Registrieren", className: "btn btn-primary" }),
                    isLoading && React.createElement("span", null,
                        React.createElement(SpinnerIcon, null),
                        "Sende Daten f\u00FCr die Registrierung...")),
                React.createElement(InfoBox, { msg: "" },
                    "Lesen Sie bitte auch unsere ",
                    React.createElement("a", { href: "https://www.pages.colouring.london/data-ethics" }, "Grunds\u00E4tze zur Datenethik"),
                    " bevor Sie unsere Daten nutzen oder verbreiten."),
                "Haben Sie bereits einen Account?",
                React.createElement("div", { className: "buttons-container with-space" },
                    React.createElement(Link, { to: "login.html", className: "btn btn-outline-dark" }, "Log in")))),
        React.createElement("hr", null),
        React.createElement("section", { className: "main-col" },
            React.createElement(SupporterLogos, null))));
};
