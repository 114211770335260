import React from 'react';
import ErrorBox from '../components/error-box';
import InfoBox from '../components/info-box';
let config = require('../../cc-config.json');
export default class ForgottenPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: undefined,
            success: undefined,
            email: undefined,
            emailUsed: undefined
        };
    }
    handleChange(event) {
        const { name, value } = event.currentTarget;
        this.setState({ [name]: value });
    }
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ error: undefined, success: undefined });
        const emailSent = this.state.email;
        try {
            const res = await fetch('/api/users/password', {
                method: 'PUT',
                body: JSON.stringify({ email: emailSent }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await res.json();
            if (data.error != undefined) {
                this.setState({ error: data.error });
            }
            else if (data.success === true) {
                this.setState({ success: true, emailUsed: emailSent });
            }
            else {
                this.setState({ error: 'Etwas schlug fehl.' });
            }
        }
        catch (err) {
            this.setState({ error: 'Etwas schlug fehl.' });
        }
    }
    render() {
        return (React.createElement("article", null,
            React.createElement("section", { className: "main-col" },
                React.createElement("h1", { className: "h2" }, "Passwort zur\u00FCcksetzen"),
                React.createElement("p", null, "Bitte geben Sie die f\u00FCr den Account genutzte E-Mail Adresse an. Ein Link zum Zur\u00FCcksetzen des Passworts wird an diese E-Mail Adresse verschickt."),
                React.createElement(ErrorBox, { msg: this.state.error }),
                React.createElement(InfoBox, { msg: "" }, this.state.success ?
                    `Wenn diese E-Mail Adresse schon bei Colouring ${config.cityName} registriert ist, wird ein Passwort zum Zurücksetzen an folgende E-Mail Adresse verschickt:  ${this.state.emailUsed}. Bitte prüfen Sie Ihren Posteingang.` :
                    null),
                React.createElement("form", { onSubmit: e => this.handleSubmit(e) },
                    React.createElement("label", { htmlFor: "email" }, "E-Mail"),
                    React.createElement("input", { name: "email", id: "email", className: "form-control", type: "email", placeholder: "Ihre E-Mail Adresse", required: true, onChange: e => this.handleChange(e) }),
                    React.createElement("div", { className: "buttons-container" },
                        React.createElement("input", { type: "submit", value: "E-Mail zum Zur\u00FCcksetzen anfordern", className: "btn btn-primary" }))))));
    }
}
