import React from 'react';
const CodeOfConductPage = () => (React.createElement("article", null,
    React.createElement("section", { className: "main-col" },
        React.createElement("h1", { className: "h2" }, "Verhaltenskodex f\u00FCr Mitwirkende"),
        React.createElement("p", null,
            "Dieser Verhaltenskodex entspricht dem Verhaltenskodex f\u00FCr Mitwirkende, Version 2.0, verf\u00FCgbar unter ",
            React.createElement("a", { href: "https://www.contributor-covenant.org/de/version/2/0/code_of_conduct/", target: "_blank" }, "https://www.contributor-covenant.org/de/version/2/0/code_of_conduct/")),
        React.createElement("h2", { className: "h3" }, "Unsere Verpflichtung"),
        React.createElement("p", null, "Wir als Mitglieder, Teilnehmende und Verantwortliche unserer Gemeinschaft verpflichten uns, allen Teilnehmenden an dem Projekt und unserer Gemeinschaft eine diskriminierungsfreie Beteiligung zu erm\u00F6glichen, unabh\u00E4ngig von Alter, K\u00F6rpergr\u00F6\u00DFe, Behinderung, ethnischer Zuordnung, Geschlechtermerkmalen, -identit\u00E4t und -ausdruck, Grad der Erfahrung, Bildung, sozialem Status, Nationalit\u00E4t, pers\u00F6nlicher Erscheinung, Abstammung, Religion oder sexueller Identit\u00E4t und Orientierung."),
        React.createElement("p", null, "Wir verpflichten uns, in einer Weise zu handeln und zu interagieren, die zu einer offenen, einladenden, vielf\u00E4ltigen, inklusiven und gesunden Gemeinschaft beitr\u00E4gt."),
        React.createElement("h2", { className: "h3" }, "Unsere Standards"),
        React.createElement("p", null, "Beispiele f\u00FCr Verhaltensweisen, die zu einem positiven Umfeld f\u00FCr unsere Gemeinschaft beitragen, sind:"),
        React.createElement("ul", null,
            React.createElement("li", null, "Einf\u00FChlungsverm\u00F6gen und Freundlichkeit gegen\u00FCber anderen Menschen zeigen"),
            React.createElement("li", null, "Unterschiedliche Meinungen, Standpunkte und Erfahrungen respektieren"),
            React.createElement("li", null, "Konstruktives Feedback geben und w\u00FCrdevoll annehmen"),
            React.createElement("li", null, "Verantwortung \u00FCbernehmen und uns bei denjenigen entschuldigen, die von unseren Fehlern betroffen sind, und aus den Erfahrungen lernen"),
            React.createElement("li", null, "Konzentration auf das, was nicht nur f\u00FCr uns als Individuen, sondern f\u00FCr die gesamte Gemeinschaft das Beste ist")),
        React.createElement("p", null, "Beispiele f\u00FCr inakzeptables Verhalten sind:"),
        React.createElement("ul", null,
            React.createElement("li", null, "Die Verwendung sexualisierter Sprache, Bilder oder Symbolik sowie unerw\u00FCnschte Versuche sexueller Ann\u00E4herung"),
            React.createElement("li", null, "Beleidigende oder abwertende Kommentare, pers\u00F6nliche oder politische Angriffe und Trollen"),
            React.createElement("li", null, "\u00D6ffentliche oder private Bel\u00E4stigungen"),
            React.createElement("li", null, "Das Ver\u00F6ffentlichen von privaten Informationen Anderer, wie zum Beispiel physische oder elektronische Adressen, ohne deren ausdr\u00FCckliche Erlaubnis"),
            React.createElement("li", null, "Anderes Verhalten, welches in einem professionellen Umfeld begr\u00FCndet als unangemessen betrachtet werden kann")),
        React.createElement("h2", { className: "h3" }, "Verantwortlichkeiten bei der Durchsetzung"),
        React.createElement("p", null, "Die Gemeinschaftsleitung ist verantwortlich daf\u00FCr, unsere Standards f\u00FCr ein akzeptables Verhalten klarzustellen und wird angemessen und fair korrigierende Ma\u00DFnahmen ergreifen um auf jegliches Verhalten, das sie f\u00FCr unangemessen, bedrohlich oder beleidigend h\u00E4lt, zu reagieren."),
        React.createElement("p", null, "Die Gemeinschaftsleitung hat das Recht und die Verantwortung, Kommentare, Commits, Code, Wiki-Bearbeitungen, Support-Tickets und andere Beitr\u00E4ge, die nicht mit diesem Verhaltenskodex vereinbar sind, zu entfernen, zu bearbeiten oder abzulehnen, und wird die Gr\u00FCnde f\u00FCr eine M\u00E4\u00DFigung mitteilen, wenn es angebracht ist."),
        React.createElement("h2", { className: "h3" }, "Geltungsbereich"),
        React.createElement("p", null, "Dieser Verhaltenskodex gilt f\u00FCr alle Bereiche der Gemeinschaft und gilt auch, wenn eine Einzelperson die Gemeinschaft offiziell in \u00F6ffentlichen Bereichen vertritt. Beispiele f\u00FCr die Repr\u00E4sentation unserer Gemeinschaft sind die Verwendung einer offiziellen E-Mail-Adresse, das Posten \u00FCber ein offizielles Social-Media-Konto oder das Auftreten als ernannte Vertretung bei einer Online- oder Offline-Veranstaltung."),
        React.createElement("h2", { className: "h3" }, "Durchsetzung"),
        React.createElement("p", null, "F\u00E4lle von missbr\u00E4uchlichem, bel\u00E4stigendem oder anderweitig inakzeptablem Verhalten k\u00F6nnen unter colouringdresden@ioer.de der f\u00FCr die Durchsetzung zust\u00E4ndigen Projektduchf\u00FChrenden gemeldet werden. Alle Beschwerden werden zeitnah und fair gepr\u00FCft und ermittelt."),
        React.createElement("p", null, "Die gesamte Gemeinschaftsleitung ist verpflichtet, die Privatsph\u00E4re und die Sicherheit derjenigen, die einen Vorfall gemeldet haben, zu respektieren."),
        React.createElement("h2", { className: "h3" }, "Durchsetzungsrichtlinien"),
        React.createElement("p", null, "Die Gemeinschaftsleitung wird sich bei der Bestimmung der Konsequenzen f\u00FCr jede Handlung, die ihrer Ansicht nach gegen diesen Verhaltenskodex verst\u00F6\u00DFt, an diese Richtlinien \u00FCber die Auswirkungen in der Gemeinschaft halten:"),
        React.createElement("p", null,
            React.createElement("b", null, "1. Berichtung")),
        React.createElement("p", null,
            React.createElement("b", null, "Auswirkungen in der Gemeinschaft:"),
            " Verwendung unangemessener Sprache oder anderes Verhalten, das in der Gemeinschaft als unprofessionell oder unwillkommen gilt."),
        React.createElement("p", null,
            React.createElement("b", null, "Folge:"),
            " Eine private, schriftliche Verwarnung der Gemeinschaftsleitung, die Klarheit \u00FCber die Art des Versto\u00DFes und eine Erkl\u00E4rung daf\u00FCr bietet, warum das Verhalten unangemessen war. Eine \u00F6ffentliche Entschuldigung kann verlangt werden."),
        React.createElement("p", null,
            React.createElement("b", null, "2. Verwarnung")),
        React.createElement("p", null,
            React.createElement("b", null, "Auswirkungen in der Gemeinschaft:"),
            " Eine Verletzung durch einen einzelnen Vorfall oder eine Reihe von Handlungen."),
        React.createElement("p", null,
            React.createElement("b", null, "Folge:"),
            " Eine Warnung mit Konsequenzen bei wiederholtem Fehlverhalten. Keine Interaktion mit den beteiligten Personen, einschlie\u00DFlich unaufgeforderter Interaktion mit denjenigen, die den Verhaltenskodex durchsetzen, f\u00FCr einen bestimmten Zeitraum. Dazu geh\u00F6rt die Vermeidung von Interaktionen in Gemeinschaftsr\u00E4umen sowie in externen Kan\u00E4len wie sozialen Medien. Ein Versto\u00DF gegen diese Bedingungen kann zu einem vor\u00FCbergehenden oder dauerhaften Verbot f\u00FChren."),
        React.createElement("p", null,
            React.createElement("b", null, "3. Vor\u00FCbergehender Ausschluss")),
        React.createElement("p", null,
            React.createElement("b", null, "Auswirkungen in der Gemeinschaft:"),
            " Eine schwerwiegende Verletzung von Gemeinschaftsstandards, einschlie\u00DFlich anhaltend unangemessenen Verhaltens."),
        React.createElement("p", null,
            React.createElement("b", null, "Folge:"),
            " Ein zeitlich begrenztes Verbot jeglicher Art von Interaktion oder \u00F6ffentlicher Kommunikation mit der Gemeinschaft. W\u00E4hrend dieses Zeitraums ist keine \u00F6ffentliche oder private Interaktion mit den beteiligten Personen erlaubt. Auch keine unaufgeforderte Interaktion mit denjenigen, die den Verhaltenskodex durchsetzen. Ein Versto\u00DF gegen diese Bedingungen kann zu einem dauerhaften Verbot f\u00FChren."),
        React.createElement("p", null,
            React.createElement("b", null, "4. Dauerhafter Ausschluss")),
        React.createElement("p", null,
            React.createElement("b", null, "Auswirkungen in der Gemeinschaft:"),
            " Aufzeigen eines Musters von Verletzungen der Gemeinschaftsstandards, einschlie\u00DFlich anhaltend unangemessenen Verhaltens, Bel\u00E4stigung einer Person oder Aggression gegen oder Herabsetzung von Gruppen von Personen."),
        React.createElement("p", null,
            React.createElement("b", null, "Folge:"),
            " Ein dauerhaftes Verbot jeglicher Art von \u00F6ffentlicher Interaktion innerhalb der Gemeinschaft."),
        React.createElement("h2", { className: "h3" }, "Bezug"),
        React.createElement("p", null,
            "Dieser Verhaltenskodex basiert auf dem Contributor Covenant, Version 2.0, verf\u00FCgbar unter ",
            React.createElement("a", { href: "https://www.contributor-covenant.org/de/version/2/0/code_of_conduct", target: "_blank" }, "https://www.contributor-covenant.org/de/version/2/0/code_of_conduct")),
        React.createElement("p", null,
            "Community Impact Guidelines wurden von ",
            React.createElement("a", { href: "https://github.com/mozilla/inclusion", target: "_blank" }, "Mozillas Code of Conduct Enforcement Ladder"),
            " inspiriert."),
        React.createElement("p", null,
            "Antworten auf h\u00E4ufig gestellte Fragen zu diesem Verhaltenskodex finden Sie in den h\u00E4ufig gestellten Fragen unter ",
            React.createElement("a", { href: "https://www.contributor-covenant.org/faq", target: "_blank" }, "https://www.contributor-covenant.org/faq"),
            " (in Englisch). Andere \u00DCbersetzungen sind verf\u00FCgbar unter ",
            React.createElement("a", { href: "https://www.contributor-covenant.org/translations", target: "_blank" }, "https://www.contributor-covenant.org/translations"),
            "."))));
export default CodeOfConductPage;
