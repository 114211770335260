import React from 'react';
import '../../map/map-button.css';
import withCopyEdit from '../data-container';
import InfoBox from '../../components/info-box';
import './community.css';
import { useDisplayPreferences } from '../../displayPreferences-context';
/**
* Community view/edit section
*/
const CommunityView = (props) => {
    var _a;
    const switchToTypologyMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('typology_likes');
    };
    const switchToLocalSignificanceMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('community_local_significance_total');
    };
    const switchToExpectedApplicationMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('community_expected_planning_application_total');
    };
    const switchToPublicOwnershipMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('community_in_public_ownership');
    };
    const { darkLightTheme } = useDisplayPreferences();
    const worthKeepingReasonsNonEmpty = Object.values((_a = props.building.community_type_worth_keeping_reasons) !== null && _a !== void 0 ? _a : {}).some(x => x);
    return React.createElement(React.Fragment, null,
        React.createElement(InfoBox, null, "Dieser Abschnitt ist noch in der Entwicklung."));
};
const CommunityContainer = withCopyEdit(CommunityView);
export default CommunityContainer;
