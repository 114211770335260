import React from 'react';
import { Link } from 'react-router-dom';
/**
 * Component to fall back on in case of 404 or no other match
 */
export const NotFound = () => (React.createElement("article", null,
    React.createElement("section", { className: "main-col" },
        React.createElement("h1", { className: "h1" }, "Seite nicht gefunden"),
        React.createElement("p", { className: "lead" }, "Die Seite konnte nicht gefunden werden."),
        React.createElement(Link, { className: "btn btn-outline-dark", to: "/" }, "Zur\u00FCck zur Startseite"))));
