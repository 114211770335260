import React, { Fragment } from 'react';
import './supporter-logos.css';
const SupporterLogos = () => (React.createElement(Fragment, null,
    React.createElement("ul", { className: "logo-list" },
        React.createElement("li", null,
            React.createElement("a", { href: "https://ioer.de/", target: "_blank" },
                React.createElement("img", { className: "cl-logo", src: "images/logo-ioer-de.svg", alt: "Leibniz-Institut f\u00FCr \u00F6kologische Raumentwicklung (I\u00D6R) e.V." }))),
        React.createElement("li", null,
            React.createElement("a", { href: "https://pages.colouring.london/colouring-cities", target: "_blank" },
                React.createElement("img", { className: "cl-logo", src: "images/logo-cc.jpg", alt: "Colouring Cities Research Programme" }))),
        React.createElement("li", null,
            React.createElement("a", { href: "https://www.citizenscience-wettbewerb.de/", target: "_blank" },
                React.createElement("img", { className: "cl-logo", src: "images/AdP Banner Wortmarke 560x300 300dpi (3) (1).png", alt: "Auf die Pl\u00E4tze! Citizen Science in deiner Stadt." }))),
        React.createElement("li", null,
            React.createElement("a", { href: "https://www.wissenschaft-im-dialog.de/", target: "_blank" },
                React.createElement("img", { className: "wid-logo", src: "images/01_LOGO_WID-rgb.png", alt: "Wissenschaft im Dialog" }))),
        React.createElement("li", null,
            React.createElement("a", { href: "https://www.museumfuernaturkunde.berlin/de", target: "_blank" },
                React.createElement("img", { className: "cl-logo", src: "images/02_mfn_logo_STANDARD_auf_weiss-kl.png", alt: "Museum f\u00FCr Naturkunde Berlin/ Leibniz-Institut f\u00FCr Evolutions- und Biodiversit\u00E4tsforschung" }))),
        React.createElement("li", null,
            React.createElement("a", { href: "https://www.buergerschaffenwissen.de/", target: "_blank" },
                React.createElement("img", { className: "cl-logo", src: "images/BsW_Logo-m-Claim-WEB-kl.png", alt: "B\u00FCrger schaffen Wissen" }))),
        React.createElement("li", null,
            React.createElement("a", { href: "https://www.bmbf.de/", target: "_blank" },
                React.createElement("img", { className: "cl-logo", src: "images/BMBF_gefoerdert_vom_deutsch_CMYK.png", alt: "Bundesministerium f\u00FCr Bildung und Forschung" }))),
        React.createElement("li", null,
            React.createElement("a", { href: "https://www.bmbf.de/bmbf/de/ueber-uns/wissenschaftskommunikation-und-buergerbeteiligung/buergerbeteiligung/citizen-science/buergerforschung.html", target: "_blank" },
                React.createElement("img", { className: "cl-logo", src: "images/BForschung_Logo_web-kl.png", alt: "B\u00FCrgerforschung Wissenschaft f\u00FCr alle" }))))));
export default SupporterLogos;
