import React from 'react';
let ccconfig = require('../../cc-config.json');
const ContactPage = () => (React.createElement("article", null,
    React.createElement("section", { className: "main-col" },
        React.createElement("h1", { className: "h2" }, "Kontakt"),
        React.createElement("p", null,
            React.createElement("b", null, "Projektwebseite \"Colouring Dresden\": "),
            " ",
            React.createElement("a", { href: "https://colouring.dresden.ioer.info", target: "_blank" }, "https://colouring.dresden.ioer.info")),
        React.createElement("p", null,
            React.createElement("b", null, "E-Mail: "),
            " colouringdresden@ioer.de"),
        React.createElement("p", null,
            React.createElement("b", null, "Newsletter: "),
            " ",
            React.createElement("a", { href: "https://seu2.cleverreach.com/f/203678-351100/", target: "_blank" }, "hier zur Anmeldung zum Newsletter")),
        React.createElement("p", null,
            React.createElement("b", null, "Mastodon: "),
            " ",
            React.createElement("a", { href: "https://wisskomm.social/@ioer/", target: "_blank" }, "@ioer@willkomm.social")),
        React.createElement("p", null,
            React.createElement("b", null, "Bluesky: "),
            " ",
            React.createElement("a", { href: "https://t.co/v0GRbpZYh1", target: "_blank" }, "http://ioer.bsky.social")),
        React.createElement("p", null,
            React.createElement("b", null, "Instagram: "),
            " ",
            React.createElement("a", { href: "https://www.instagram.com/colouringdd/", target: "_blank" }, "https://www.instagram.com/colouringdd/")))));
export default ContactPage;
