import React from 'react';
import { Link } from 'react-router-dom';
let config = require('../../cc-config.json');
import Categories from '../building/categories';
import './welcome.css';
const Welcome = () => (React.createElement("div", { className: "section-body welcome" },
    React.createElement(Categories, { mode: "view" }),
    React.createElement("h1", { className: "h2" },
        "Willkommen bei Colouring ",
        config.cityName,
        "!"),
    React.createElement("p", null, "Wie alt sind die Geb\u00E4ude Dresdens? Aus welchen Materialien wurden sie \u00FCberwiegend erbaut? Und wie gut sind sie baulich vorbereitet, um mit extremen Ereignissen wie Starkregen, Hochwasser oder Hitze umgehen zu k\u00F6nnen?"),
    React.createElement("p", null,
        "Um solche und weitere Fragen beantworten zu k\u00F6nnen, soll Wissen zu den Geb\u00E4uden der Stadt ",
        config.cityName,
        " f\u00FCr verschiedene Merkmale in einer offenen Wissensplattform erfasst, in Karten visualisiert und als offene Daten bereitgestellt werden. Sie interessieren sich f\u00FCr die Geb\u00E4ude der Stadt ",
        config.cityName,
        " haben und m\u00F6chten Ihr Wissen in einer interaktiven Karte erfassen und teilen? Dann sind Sie auf der Plattform \"Colouring ",
        config.cityName,
        "\" genau richtig!"),
    React.createElement("p", null,
        React.createElement("b", null, "Wie kann ich mitmachen?")),
    React.createElement("p", null,
        "Sie k\u00F6nnen individuell beginnen und Geb\u00E4udemerkmale eintragen - egal ob am heimischen Computer oder drau\u00DFen an der frischen Luft. Einfach registieren und loslegen. Weiterhin werden unterschiedliche Veranstaltungen wie Vortr\u00E4ge, Spazierg\u00E4nge (\"Mapathons\") und ein (digitaler) monatlicher Stammtisch zum Erfahrungsaustausch angeboten. Weitere Informationen gibt es im ",
        React.createElement("a", { href: "https://seu2.cleverreach.com/f/203678-351100/", target: "_blank" }, "Newsletter"),
        " oder auf der ",
        React.createElement("a", { href: "https://colouring.dresden.ioer.info", target: "_blank" }, "Projektwebseite"),
        "."),
    React.createElement("p", null,
        React.createElement("b", null, "Zum Projekt:")),
    React.createElement("p", null,
        "Die Plattform Colouring ",
        config.cityName,
        " wird im Rahmen des Citizen Science Projektes \"Baukultur und klimagerechte Architektur in Dresden. Geb\u00E4udewissen kartieren, erforschen und vermitteln\" weiterentwickelt und f\u00FCr das gemeinsame Erfassen der Geb\u00E4udemerkmale genutzt. Weitere Informationen sind  ",
        React.createElement("a", { href: "https://colouring.dresden.ioer.info", target: "_blank" }, "hier auf der Projektwebseite"),
        " zu finden."),
    React.createElement("p", null,
        "Colouring ",
        config.cityName,
        " ist Teil des internationalen Forschungsnetzwerkes Colouring Cities Research Programme (CCRP), welches am Alan Turing Institut angesiedelt ist (dem nationalen Institut des Vereinigten K\u00F6nigreichs f\u00FCr Data Science und k\u00FCnstliche Intelligenz). Das Forschungsnetzwerk arbeitet mit lokalen, regionalen, nationalen und internationalen Partnern an der Weiterentwicklung des offenen Programmcodes, und kann auf weitere St\u00E4dte \u00FCbertragen werden. Das Forschungsnetzwerk CCRP pflegt ein ",
        React.createElement("a", { href: "https://github.com/colouring-cities/manual/wiki", target: "_blank" }, "offenes Wiki"),
        " auf GitHub, um \u00FCber die Entwicklung und Aktivit\u00E4ten zu informieren."),
    React.createElement("p", null, "Neue Datens\u00E4tze oder Funktionen werden kontinuierlich erg\u00E4nzt. F\u00FCr Ihre Mitwirkung bei der Erfassung oder Verifikation von Geb\u00E4udemerkmalen sind wir dankbar!"),
    React.createElement("p", null,
        "Die ",
        React.createElement(Link, { to: "/data-extracts.html" }, "Daten"),
        " sowie der ",
        React.createElement("a", { href: "https://github.com/colouring-cities/colouring-dresden", target: "_blank" }, "Programmcode"),
        " stehen frei zum Herunterladen unter offenen Lizenzen bereit."),
    React.createElement("p", null,
        "Sch\u00F6n, dass Sie zu Colouring ",
        config.cityName,
        " gefunden haben! Und nun viel Spa\u00DF beim Entdecken, Erfassen und Erforschen des Geb\u00E4udebestandes der Stadt Dresden."),
    React.createElement(Link, { to: "/view/categories", className: "btn btn-outline-dark btn-lg btn-block" },
        React.createElement("b", null,
            "Let\u00B4s colour ",
            config.cityName,
            "!")),
    React.createElement("div", { className: "image-row" },
        React.createElement("a", { href: "https://ioer.de/", target: "_blank" },
            React.createElement("img", { className: "cl-logo", src: "images/logo-ioer-de.svg", alt: "Leibniz-Institut f\u00FCr \u00F6kologische Raumentwicklung (I\u00D6R) e.V." })),
        React.createElement("a", { href: "https://pages.colouring.london/colouring-cities", target: "_blank" },
            React.createElement("img", { className: "cl-logo", src: "images/logo-cc.jpg", alt: "Colouring Cities Research Programme" }))),
    React.createElement("div", { className: "image-row" },
        React.createElement("a", { href: "https://www.citizenscience-wettbewerb.de/", target: "_blank" },
            React.createElement("img", { className: "cl-logo", src: "images/AdP Banner Wortmarke 560x300 300dpi (3) (1).png", alt: "Auf die Pl\u00E4tze! Citizen Science in deiner Stadt." })),
        React.createElement("a", { href: "https://www.wissenschaft-im-dialog.de/", target: "_blank" },
            React.createElement("img", { className: "wid-logo", src: "images/01_LOGO_WID-rgb.png", alt: "Wissenschaft im Dialog" })),
        React.createElement("a", { href: "https://www.museumfuernaturkunde.berlin/de", target: "_blank" },
            React.createElement("img", { className: "cl-logo", src: "images/02_mfn_logo_STANDARD_auf_weiss-kl.png", alt: "Museum f\u00FCr Naturkunde Berlin/ Leibniz-Institut f\u00FCr Evolutions- und Biodiversit\u00E4tsforschung" }))),
    React.createElement("div", { className: "image-row" },
        React.createElement("a", { href: "https://www.buergerschaffenwissen.de/", target: "_blank" },
            React.createElement("img", { className: "cl-logo", src: "images/BsW_Logo-m-Claim-WEB-kl.png", alt: "B\u00FCrger schaffen Wissen" })),
        React.createElement("a", { href: "https://www.bmbf.de/", target: "_blank" },
            React.createElement("img", { className: "cl-logo", src: "images/BMBF_gefoerdert_vom_deutsch_CMYK.png", alt: "Bundesministerium f\u00FCr Bildung und Forschung" })),
        React.createElement("a", { href: "https://www.bmbf.de/bmbf/de/ueber-uns/wissenschaftskommunikation-und-buergerbeteiligung/buergerbeteiligung/citizen-science/buergerforschung.html", target: "_blank" },
            React.createElement("img", { className: "cl-logo", src: "images/BForschung_Logo_web-kl.png", alt: "B\u00FCrgerforschung Wissenschaft f\u00FCr alle" })))));
export default Welcome;
