import React, { Fragment } from 'react';
import { dataFields } from '../../config/data-fields-config';
import DataEntry from '../data-components/data-entry';
import SelectDataEntry from '../data-components/select-data-entry';
import Verification from '../data-components/verification';
import withCopyEdit from '../data-container';
const AttachmentFormOptions = [
    "freistehend",
    "Doppelhaushälfte",
    "Häuserreihe (Ende)",
    "Häuserreihe (innerhalb)"
];
/**
* Type view/edit section
*/
const TypeView = (props) => {
    return (React.createElement(Fragment, null,
        React.createElement(SelectDataEntry, { title: dataFields.building_attachment_form.title, slug: "building_attachment_form", value: props.building.building_attachment_form, tooltip: dataFields.building_attachment_form.tooltip, options: AttachmentFormOptions, mode: props.mode, copy: props.copy, onChange: props.onChange }),
        React.createElement(Verification, { slug: "building_attachment_form", allow_verify: props.user !== undefined && props.building.building_attachment_form !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("building_attachment_form"), user_verified_as: props.user_verified.building_attachment_form, verified_count: props.building.verified.building_attachment_form }),
        React.createElement(SelectDataEntry, { title: dataFields.size_roof_shape.title, slug: "size_roof_shape", value: props.building.size_roof_shape, tooltip: dataFields.size_roof_shape.tooltip, mode: props.mode, copy: props.copy, onChange: props.onChange, 
            /* disabled={true} */
            options: dataFields.size_roof_shape.items }),
        React.createElement(Verification, { slug: "size_roof_shape", allow_verify: props.user !== undefined && props.building.size_roof_shape !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("size_roof_shape"), user_verified_as: props.user_verified.size_roof_shape, verified_count: props.building.verified.size_roof_shape }),
        React.createElement(DataEntry, { title: dataFields.size_roof_shape_source.title, tooltip: dataFields.size_roof_shape_source.tooltip, slug: "size_roof_shape_source", value: props.building.size_roof_shape_source, mode: props.mode, 
            /* disabled={true} */
            copy: props.copy, onChange: props.onChange }),
        React.createElement(Verification, { slug: "size_roof_shape_source", allow_verify: props.user !== undefined && props.building.size_roof_shape_source !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("size_roof_shape_source"), user_verified_as: props.user_verified.size_roof_shape_source, verified_count: props.building.verified.size_roof_shape_source }),
        React.createElement(SelectDataEntry, { title: dataFields.building_owner.title, slug: "building_owner", value: props.building.building_owner, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.building_owner.tooltip, placeholder: dataFields.building_owner.example, options: dataFields.building_owner.items }),
        React.createElement(Verification, { slug: "building_owner", allow_verify: props.user !== undefined && props.building.building_owner !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("building_owner"), user_verified_as: props.user_verified.building_owner, verified_count: props.building.verified.building_owner }),
        React.createElement(DataEntry, { title: dataFields.building_owner_source.title, tooltip: dataFields.building_owner_source.tooltip, slug: "building_owner_source", value: props.building.building_owner_source, mode: props.mode, 
            /* disabled={true} */
            copy: props.copy, onChange: props.onChange }),
        React.createElement(Verification, { slug: "building_owner_source", allow_verify: props.user !== undefined && props.building.building_owner_source !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("building_owner_source"), user_verified_as: props.user_verified.building_owner_source, verified_count: props.building.verified.building_owner_source })));
};
const TypeContainer = withCopyEdit(TypeView);
export default TypeContainer;
