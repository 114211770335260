import React from 'react';
import InfoBox from '../../components/info-box';
import withCopyEdit from '../data-container';
/**
* Team view/edit section
*/
const TeamView = (props) => {
    const building = props.building;
    const currentYear = new Date().getFullYear();
    const currentBuildingConstructionYear = building.date_year || undefined;
    return (React.createElement("form", null,
        React.createElement(InfoBox, null, "Dieser Abschnitt ist noch in der Entwicklung.")));
};
const TeamContainer = withCopyEdit(TeamView);
export default TeamContainer;
