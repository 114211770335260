import React, { Component, Fragment } from 'react';
import { VerifyIcon } from '../../components/icons';
import './verification.css';
class Verification extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(verify) {
        return (e) => {
            e.preventDefault();
            const x = e.clientX / document.body.clientWidth;
            const y = e.clientY / document.body.clientHeight;
            this.props.onVerify(this.props.slug, verify, x, y);
        };
    }
    render() {
        const props = this.props;
        let user_verified_as = props.user_verified_as;
        if (typeof user_verified_as === 'boolean') {
            user_verified_as = user_verified_as ? 'Yes' : 'No';
        }
        return (React.createElement("div", { className: "verification-container" },
            React.createElement("span", { className: "verification-status", title: `Verified by ${props.verified_count} ${(props.verified_count == 1) ? "person" : "people"}` },
                React.createElement(VerifyIcon, null),
                props.verified_count || 0),
            props.user_verified ?
                React.createElement(Fragment, null,
                    "Verifiziert als \"",
                    React.createElement("span", null, user_verified_as),
                    "\"",
                    React.createElement("button", { className: "btn btn-danger", title: "Remove my verification", disabled: !props.allow_verify, onClick: this.handleClick(false) }, "Zur\u00FCcknehmen"))
                :
                    React.createElement(Fragment, null,
                        React.createElement("button", { className: "btn btn-success", title: "Confirm that the current value is correct", disabled: !props.allow_verify, onClick: this.handleClick(true) }, "Verifizieren"))));
    }
}
export default Verification;
