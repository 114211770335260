import React from 'react';
import InfoBox from '../../components/info-box';
import { dataFields } from '../../config/data-fields-config';
import DataEntry from '../data-components/data-entry';
import { DataEntryGroup } from '../data-components/data-entry-group';
import NumericDataEntry from '../data-components/numeric-data-entry';
import withCopyEdit from '../data-container';
import SelectDataEntry from '../data-components/select-data-entry';
import Verification from '../data-components/verification';
import { MultiSelectDataEntry } from '../data-components/multi-select-data-entry';
import { DynamicsDataEntryThermalStressObjective } from './dynamics/dynamics-data-entry-thermal-stress-objective';
import { DynamicsDataEntryThermalStressSubjective } from './dynamics/dynamics-data-entry-thermal-stress-subjective';
import { DynamicsDataEntryRainFloodHistoricIncidents } from './dynamics/dynamics-data-entry-rain-flood-historic-incidents';
import './resilience.css';
/**
* Dynamics view/edit section
*/
const ResilienceView = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(DataEntryGroup, { name: "Hitze", collapsed: false },
            React.createElement(DataEntryGroup, { name: "Fassaden- und Dachmerkmale", collapsed: false },
                React.createElement(SelectDataEntry, { title: dataFields.roof_colour.title, slug: "roof_colour", value: props.building.roof_colour, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.roof_colour.tooltip, placeholder: dataFields.roof_colour.example, options: dataFields.roof_colour.items }),
                React.createElement(Verification, { slug: "roof_colour", allow_verify: props.user !== undefined && props.building.roof_colour !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("roof_colour"), user_verified_as: props.user_verified.roof_colour, verified_count: props.building.verified.roof_colour }),
                React.createElement(SelectDataEntry, { title: dataFields.roof_colour_type.title, slug: "roof_colour_type", value: props.building.roof_colour_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.roof_colour_type.tooltip, placeholder: dataFields.roof_colour_type.example, options: dataFields.roof_colour_type.items }),
                React.createElement(Verification, { slug: "roof_colour_type", allow_verify: props.user !== undefined && props.building.roof_colour_type !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("roof_colour_type"), user_verified_as: props.user_verified.roof_colour_type, verified_count: props.building.verified.roof_colour_type }),
                React.createElement(SelectDataEntry, { title: dataFields.facade_colour.title, slug: "facade_colour", value: props.building.facade_colour, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.facade_colour.tooltip, placeholder: dataFields.facade_colour.example, options: dataFields.facade_colour.items }),
                React.createElement(Verification, { slug: "facade_colour", allow_verify: props.user !== undefined && props.building.facade_colour !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("facade_colour"), user_verified_as: props.user_verified.facade_colour, verified_count: props.building.verified.facade_colour }),
                React.createElement(SelectDataEntry, { title: dataFields.facade_window_percentage.title, slug: "facade_window_percentage", value: props.building.facade_window_percentage, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.facade_window_percentage.tooltip, placeholder: dataFields.facade_window_percentage.example, options: dataFields.facade_window_percentage.items }),
                React.createElement(Verification, { slug: "facade_window_percentage", allow_verify: props.user !== undefined && props.building.facade_window_percentage !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("facade_window_percentage"), user_verified_as: props.user_verified.facade_window_percentage, verified_count: props.building.verified.facade_window_percentage }),
                React.createElement("div", { className: 'div-direction_of_windows' },
                    React.createElement(MultiSelectDataEntry, { slug: 'direction_of_windows', title: dataFields.direction_of_windows.title, value: props.building.direction_of_windows, disabled: false, 
                        /* disabled={!props.building.community_type_worth_keeping} */
                        onChange: props.onSaveChange, options: Object.entries(dataFields.direction_of_windows.fields)
                            .map(([key, definition]) => ({
                            key,
                            label: definition.title
                        })), mode: props.mode }))),
            React.createElement(DataEntryGroup, { name: "Hitzebelastung", collapsed: false },
                React.createElement("div", { className: 'div-data-title-bold' },
                    React.createElement(React.Fragment, null,
                        React.createElement(DynamicsDataEntryThermalStressObjective
                        /*
                            Will clear the edits and new record data upon navigating to another building.
                            Should get a better way to do this, plus a way to actually keep unsaved edits.
                        */
                        , { 
                            /*
                                Will clear the edits and new record data upon navigating to another building.
                                Should get a better way to do this, plus a way to actually keep unsaved edits.
                            */
                            key: props.building.building_id, value: props.building.thermal_stress_objective, editableEntries: true, slug: 'thermal_stress_objective', title: dataFields.thermal_stress_objective.title, mode: props.mode, onChange: props.onChange, onSaveAdd: props.onSaveAdd, hasEdits: props.edited }),
                        props.mode === 'view' &&
                            React.createElement(InfoBox, null, "Bitte in den Bearbeitungsmodus wechseln um Daten bearbeiten / hinzuf\u00FCgen zu k\u00F6nnen")),
                    React.createElement(React.Fragment, null,
                        React.createElement(DynamicsDataEntryThermalStressSubjective
                        /*
                            Will clear the edits and new record data upon navigating to another building.
                            Should get a better way to do this, plus a way to actually keep unsaved edits.
                        */
                        , { 
                            /*
                                Will clear the edits and new record data upon navigating to another building.
                                Should get a better way to do this, plus a way to actually keep unsaved edits.
                            */
                            key: props.building.building_id, value: props.building.thermal_stress_subjective, editableEntries: true, slug: 'thermal_stress_subjective', title: dataFields.thermal_stress_subjective.title, mode: props.mode, onChange: props.onChange, onSaveAdd: props.onSaveAdd, hasEdits: props.edited }),
                        props.mode === 'view' &&
                            React.createElement(InfoBox, null, "Bitte in den Bearbeitungsmodus wechseln um Daten bearbeiten / hinzuf\u00FCgen zu k\u00F6nnen")))),
            React.createElement(DataEntryGroup, { name: "Anpassungsma\u00DFnahmen", collapsed: false },
                React.createElement("div", { className: 'div-heat_adaption_measure' },
                    React.createElement(MultiSelectDataEntry, { slug: 'heat_adaption_measure', title: dataFields.heat_adaption_measure.title, value: props.building.heat_adaption_measure, disabled: false, 
                        /* disabled={!props.building.community_type_worth_keeping} */
                        onChange: props.onSaveChange, options: Object.entries(dataFields.heat_adaption_measure.fields)
                            .map(([key, definition]) => ({
                            key,
                            label: definition.title
                        })), mode: props.mode })),
                React.createElement(SelectDataEntry, { title: dataFields.heat_adaption_measure_source.title, slug: "heat_adaption_measure_source", value: props.building.heat_adaption_measure_source, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.heat_adaption_measure_source.tooltip, placeholder: dataFields.heat_adaption_measure_source.example, options: dataFields.heat_adaption_measure_source.items }),
                React.createElement(Verification, { slug: "heat_adaption_measure_source", allow_verify: props.user !== undefined && props.building.heat_adaption_measure_source !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("heat_adaption_measure_source"), user_verified_as: props.user_verified.heat_adaption_measure_source, verified_count: props.building.verified.heat_adaption_measure_source }))),
        React.createElement(DataEntryGroup, { name: "Starkregen / Hochwasser", collapsed: false },
            React.createElement(DataEntryGroup, { name: "Gel\u00E4ndeanbindung", collapsed: false },
                React.createElement(SelectDataEntry, { title: dataFields.terrain_connection_yesno.title, slug: "terrain_connection_yesno", value: props.building.terrain_connection_yesno, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.terrain_connection_yesno.tooltip, placeholder: dataFields.terrain_connection_yesno.example, options: dataFields.terrain_connection_yesno.items }),
                React.createElement(Verification, { slug: "terrain_connection_yesno", allow_verify: props.user !== undefined && props.building.terrain_connection_yesno !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("terrain_connection_yesno"), user_verified_as: props.user_verified.terrain_connection_yesno, verified_count: props.building.verified.terrain_connection_yesno }),
                (props.building.terrain_connection_yesno === "höher" || props.building.terrain_connection_yesno === "niedriger") ?
                    React.createElement(React.Fragment, null,
                        React.createElement(NumericDataEntry, { title: dataFields.terrain_connection_difference.title, slug: "terrain_connection_difference", value: props.building.terrain_connection_difference, mode: props.mode, copy: props.copy, tooltip: dataFields.terrain_connection_difference.tooltip, onChange: props.onChange, step: 1, min: 0 }),
                        React.createElement(Verification, { slug: "terrain_connection_difference", allow_verify: props.user !== undefined && props.building.terrain_connection_difference !== null, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("terrain_connection_difference"), user_verified_as: props.user_verified.terrain_connection_difference, verified_count: props.building.verified.terrain_connection_difference }),
                        React.createElement(SelectDataEntry, { title: dataFields.terrain_connection_difference_source.title, slug: "terrain_connection_difference_source", value: props.building.terrain_connection_difference_source, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.terrain_connection_difference_source.tooltip, placeholder: dataFields.terrain_connection_difference_source.example, options: dataFields.terrain_connection_difference_source.items }),
                        React.createElement(Verification, { slug: "terrain_connection_difference_source", allow_verify: props.user !== undefined && props.building.terrain_connection_difference_source !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("terrain_connection_difference_source"), user_verified_as: props.user_verified.terrain_connection_difference_source, verified_count: props.building.verified.terrain_connection_difference_source }))
                    :
                        React.createElement(React.Fragment, null)),
            React.createElement(DataEntryGroup, { name: "Unterkellerung", collapsed: false },
                React.createElement(SelectDataEntry, { title: dataFields.basement_type.title, slug: "basement_type", value: props.building.basement_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.basement_type.tooltip, placeholder: dataFields.basement_type.example, options: dataFields.basement_type.items }),
                React.createElement(Verification, { slug: "basement_type", allow_verify: props.user !== undefined && props.building.basement_type !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("basement_type"), user_verified_as: props.user_verified.basement_type, verified_count: props.building.verified.basement_type }),
                React.createElement(NumericDataEntry, { title: dataFields.basement_percentage.title, slug: "basement_percentage", value: props.building.basement_percentage, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 0, tooltip: dataFields.basement_percentage.tooltip }),
                React.createElement(Verification, { slug: "basement_percentage", allow_verify: props.user !== undefined && props.building.basement_percentage !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("basement_percentage"), user_verified_as: props.user_verified.basement_percentage, verified_count: props.building.verified.basement_percentage }),
                React.createElement(SelectDataEntry, { title: dataFields.basement_use.title, slug: "basement_use", value: props.building.basement_use, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.basement_use.tooltip, placeholder: dataFields.basement_use.example, options: dataFields.basement_use.items }),
                React.createElement(Verification, { slug: "basement_use", allow_verify: props.user !== undefined && props.building.basement_use !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("basement_use"), user_verified_as: props.user_verified.basement_use, verified_count: props.building.verified.basement_use }),
                React.createElement(DataEntry, { title: dataFields.basement_use_source.title, tooltip: dataFields.basement_use_source.tooltip, slug: "basement_use_source", value: props.building.basement_use_source, mode: props.mode, 
                    /* disabled={true} */
                    copy: props.copy, onChange: props.onChange }),
                React.createElement(Verification, { slug: "basement_use_source", allow_verify: props.user !== undefined && props.building.basement_use_source !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("basement_use_source"), user_verified_as: props.user_verified.basement_use_source, verified_count: props.building.verified.basement_use_source })),
            React.createElement(DataEntryGroup, { name: "Historische Betroffenheit", collapsed: false },
                React.createElement("div", { className: 'div-data-title-bold' },
                    React.createElement(React.Fragment, null,
                        React.createElement(DynamicsDataEntryRainFloodHistoricIncidents
                        /*
                            Will clear the edits and new record data upon navigating to another building.
                            Should get a better way to do this, plus a way to actually keep unsaved edits.
                        */
                        , { 
                            /*
                                Will clear the edits and new record data upon navigating to another building.
                                Should get a better way to do this, plus a way to actually keep unsaved edits.
                            */
                            key: props.building.building_id, value: props.building.rain_flood_historic_incidents, editableEntries: true, slug: 'rain_flood_historic_incidents', title: dataFields.rain_flood_historic_incidents.title, mode: props.mode, onChange: props.onChange, onSaveAdd: props.onSaveAdd, hasEdits: props.edited }),
                        props.mode === 'view' &&
                            React.createElement(InfoBox, null, "Bitte in den Bearbeitungsmodus wechseln um Daten bearbeiten / hinzuf\u00FCgen zu k\u00F6nnen")))),
            React.createElement(DataEntryGroup, { name: "Vorsorgema\u00DFnahmen", collapsed: false },
                React.createElement("div", { className: 'div-rain_flood_preventive_measures2' },
                    React.createElement(MultiSelectDataEntry, { slug: 'rain_flood_preventive_measures2', title: dataFields.rain_flood_preventive_measures2.title, value: props.building.rain_flood_preventive_measures2, disabled: false, 
                        /* disabled={!props.building.community_type_worth_keeping} */
                        onChange: props.onSaveChange, options: Object.entries(dataFields.rain_flood_preventive_measures2.fields)
                            .map(([key, definition]) => ({
                            key,
                            label: definition.title
                        })), mode: props.mode })),
                React.createElement("div", { className: 'div-rain_flood_preventive_measures3' },
                    React.createElement(MultiSelectDataEntry, { slug: 'rain_flood_preventive_measures3', title: dataFields.rain_flood_preventive_measures3.title, value: props.building.rain_flood_preventive_measures3, disabled: false, 
                        /* disabled={!props.building.community_type_worth_keeping} */
                        onChange: props.onSaveChange, options: Object.entries(dataFields.rain_flood_preventive_measures3.fields)
                            .map(([key, definition]) => ({
                            key,
                            label: definition.title
                        })), mode: props.mode })),
                React.createElement("div", { className: 'div-rain_flood_preventive_measures1' },
                    React.createElement(MultiSelectDataEntry, { slug: 'rain_flood_preventive_measures1', title: dataFields.rain_flood_preventive_measures1.title, value: props.building.rain_flood_preventive_measures1, disabled: false, 
                        /* disabled={!props.building.community_type_worth_keeping} */
                        onChange: props.onSaveChange, options: Object.entries(dataFields.rain_flood_preventive_measures1.fields)
                            .map(([key, definition]) => ({
                            key,
                            label: definition.title
                        })), mode: props.mode })),
                React.createElement(SelectDataEntry, { title: dataFields.rain_flood_preventive_measures_source.title, slug: "rain_flood_preventive_measures_source", value: props.building.rain_flood_preventive_measures_source, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.rain_flood_preventive_measures_source.tooltip, placeholder: dataFields.rain_flood_preventive_measures_source.example, options: dataFields.rain_flood_preventive_measures_source.items }),
                React.createElement(Verification, { slug: "rain_flood_preventive_measures_source", allow_verify: props.user !== undefined && props.building.rain_flood_preventive_measures_source !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("rain_flood_preventive_measures_source"), user_verified_as: props.user_verified.rain_flood_preventive_measures_source, verified_count: props.building.verified.rain_flood_preventive_measures_source })))));
};
const ResilienceContainer = withCopyEdit(ResilienceView);
export default ResilienceContainer;
