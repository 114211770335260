import React from 'react';
let config = require('../../cc-config.json');
const DeclarationOfAccessibilityPage = () => (React.createElement("article", null,
    React.createElement("section", { className: "main-col" },
        React.createElement("h1", { className: "h1" }, "Erkl\u00E4rung zur Barrierefreiheit"),
        React.createElement("p", null, "Das Leibniz-Institut f\u00FCr \u00F6kologische Raumentwicklung  ist bem\u00FCht, seine Webseiten und seine mobilen Anwendungen in \u00DCbereinstimmung mit S\u00E4chsischem Inklusionsgesetz (S\u00E4chsInklusG) und Barrierefreie-Websites-Gesetz (BfWebG) in Verbindung mit der Barrierefreie-Informationstechnik-Verordnung (BITV 2.0) barrierefrei zug\u00E4nglich zu machen. Das BfWebG wird erg\u00E4nzt durch die Barrierefreie-Websites-Verordnung (BfWebVO). Diese Gesetze sind im Einklang mit der Richtlinie (EU) 2016/2102 verfasst."),
        React.createElement("p", null, "Diese Erkl\u00E4rung zur Barrierefreiheit gilt f\u00FCr die mobile Webanwendung"),
        React.createElement("p", null,
            React.createElement("b", null, "Plattform Colouring Dresden ")),
        React.createElement("p", null,
            React.createElement("b", null, "URL: "),
            "https://colouring.dresden.ioer.de/"),
        React.createElement("h1", { className: "h2" }, "Stand der Barrierefreiheit"),
        React.createElement("p", null, "Grundlage der Barrierefreiheit sind die international g\u00FCltigen Web Content Accessibility Guidelines (WCAG 2.1) auf Konformit\u00E4tsstufe AA und die europ\u00E4ische Norm EN 301 549, Version 3.2.1. F\u00FCr PDF-Dokumente wird zus\u00E4tzlich der internationale Standard PDF/UA-1 beachtet."),
        React.createElement("p", null, "Die mobile Webanwendung ist nach den oben genannten Richtlinien nur teilweise barrierefrei."),
        React.createElement("p", null,
            "Die Plattform Colouring Dresden ist Bestandteil des Colouring Cities Research Programme (CCRP) ",
            React.createElement("a", { href: "https://www.pages.colouring.london/colouring-cities", target: "_blank" }, "https://www.pages.colouring.london/colouring-cities"),
            ", das 2020 vom Alan Turing Institut in England eingerichtet wurde."),
        React.createElement("p", null, "Design und Aufbau der mobilen Webanwendung werden gemeinsam mit dem internationalen Forschungsnetzwerk CCRP abgestimmt und weiterentwickelt. Dadurch k\u00F6nnen nur bedingt deutsche Standards erf\u00FCllt werden."),
        React.createElement("h1", { className: "h2" }, "Nicht barrierefreie Inhalte"),
        React.createElement("p", null, "Kartenbedienung ist nur teilweise \u00FCber Tastatur m\u00F6glich"),
        React.createElement("p", null, "Einige Farbkontraste entsprechen nur teilweise dem deutschen Standard"),
        React.createElement("p", null, "Schaltfl\u00E4chen haben nur teilweise erkl\u00E4rende Bezeichnungen"),
        React.createElement("h1", { className: "h2" }, "Erstellung der Erkl\u00E4rung"),
        React.createElement("p", null, "Diese Erkl\u00E4rung wurde am 06.03. 2023 erstellt. Den Stand der Barrierefreiheit ermitteln wir kontinuierlich durch eigene Pr\u00FCfung, n\u00E4chste Pr\u00FCfung im Dezember 2023."),
        React.createElement("h1", { className: "h2" }, "Feedback und Kontaktangaben"),
        React.createElement("p", null, "Wenn Sie Informationen zu nicht barrierefreien Inhalten ben\u00F6tigen oder wenn Ihnen M\u00E4ngel zur Barrierefreiheit an der mobilen Webanwendung auffallen, wenden Sie sich unbedingt an uns: barrierefreiheit@ioer.de"),
        React.createElement("p", null, "Wir werden versuchen, die mitgeteilten M\u00E4ngel zu beseitigen bzw. Ihnen nicht zug\u00E4ngliche Informationen in barrierefreier Form zur Verf\u00FCgung zu stellen."),
        React.createElement("h1", { className: "h2" }, "Durchsetzungsverfahren"),
        React.createElement("p", null, "Sie haben unter oben genanntem Kontakt eine Anfrage zur Barrierefreiheit unseren Webseiten und mobilen Anwendungen gestellt. Falls dabei innerhalb einer angemessenen Frist (in der Regel vier Wochen) keine zufriedenstellende L\u00F6sung gefunden wird, k\u00F6nnen Sie sich an die f\u00FCr das Durchsetzungsverfahren zust\u00E4ndige Gesch\u00E4ftsstelle des Landesbeauftragten f\u00FCr Inklusion der Menschen mit Behinderungen (Durchsetzungsstelle) bei der S\u00E4chsischen Staatskanzlei wenden. Die Durchsetzungsstelle unterst\u00FCtzt eine au\u00DFergerichtliche Streitbeilegung, wenn Konflikte zwischen \u00F6ffentlichen Stellen in Sachsen und Nutzenden der Webseiten bzw. mobilen Anwendungen auftreten. Dieses Schlichtungsverfahren ist kostenlos. Die Einschaltung eines Rechtsbeistands ist nicht erforderlich."),
        React.createElement("p", null,
            "Kontakt:",
            React.createElement("br", null),
            "S\u00E4chsische Staatskanzlei",
            React.createElement("br", null),
            "Gesch\u00E4ftsstelle des Landesbeauftragten f\u00FCr Inklusion der Menschen mit Behinderungen",
            React.createElement("br", null),
            "Durchsetzungsstelle",
            React.createElement("br", null),
            "Archivstra\u00DFe 1",
            React.createElement("br", null),
            "01097 Dresden",
            React.createElement("br", null),
            "Telefon: 0351 564 10713",
            React.createElement("br", null),
            "Fax: 0351 564 10999",
            React.createElement("br", null),
            "E-Mail: durchsetzungsstelle@sk.sachsen.de",
            React.createElement("br", null),
            "Webseite: ",
            React.createElement("a", { href: "https://www.durchsetzungsstelle.sachsen.de", target: "_blank" }, "https://www.durchsetzungsstelle.sachsen.de"),
            React.createElement("br", null)))));
export default DeclarationOfAccessibilityPage;
