import React from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import ErrorBox from '../components/error-box';
export default class PasswordReset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: undefined,
            success: undefined,
            token: undefined,
            password: undefined,
            confirmPassword: undefined
        };
    }
    componentDidMount() {
        const queryParams = new URLSearchParams(this.props.location.search);
        const token = queryParams.get('token');
        if (token == undefined) {
            this.setState({ error: PasswordReset.tokenMissingMessage });
        }
        else {
            this.setState({ token: token });
        }
    }
    handleChange(event) {
        const { name, value } = event.currentTarget;
        this.setState({
            [name]: value,
        });
    }
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ error: undefined });
        if (this.state.token == undefined) {
            this.setState({ error: PasswordReset.tokenMissingMessage });
            return;
        }
        if (this.state.password !== this.state.confirmPassword) {
            this.setState({ error: 'Passwörter stimmen nicht überein!' });
            return;
        }
        const requestData = {
            token: this.state.token,
            password: this.state.password
        };
        const res = await fetch('/api/users/password', {
            method: 'PUT',
            body: JSON.stringify(requestData),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await res.json();
        if (data.error != undefined) {
            this.setState({ error: data.error });
        }
        else if (data.success === true) {
            this.setState({ success: true });
        }
        else {
            this.setState({ error: 'Unerwartetes Ergebnis.' });
        }
    }
    render() {
        if (this.state.success) {
            return React.createElement(Redirect, { to: "/my-account.html" });
        }
        return (React.createElement("article", null,
            React.createElement("section", { className: "main-col" },
                React.createElement("h1", { className: "h2" }, "Passwort vergessen"),
                React.createElement("p", null,
                    "Bitte geben Sie ein neues Passwort f\u00FCr Ihren Account ein. Sollte ihr Token zum Zur\u00FCcksetzen des Passworts nicht funktionieren, gehen Sie bitte zur\u00FCck zur ",
                    React.createElement(Link, { to: "/forgotten-password.html" }, "Passwort vergessen Seite"),
                    ", um einen neuen Link anzufordern."),
                React.createElement(ErrorBox, { msg: this.state.error }),
                React.createElement("form", { onSubmit: e => this.handleSubmit(e) },
                    React.createElement("label", { htmlFor: "email" }, "Neues Passwort"),
                    React.createElement("input", { name: "password", className: "form-control", type: "password", placeholder: "Neues Passwort", required: true, onChange: e => this.handleChange(e) }),
                    React.createElement("label", { htmlFor: "email" }, "Neues Passwort best\u00E4tigen"),
                    React.createElement("input", { name: "confirmPassword", className: "form-control", type: "password", placeholder: "Neues Passwort best\u00E4tigen", required: true, onChange: e => this.handleChange(e) }),
                    React.createElement("input", { name: "token", id: "token", type: "hidden", value: this.state.token }),
                    React.createElement("div", { className: "buttons-container" },
                        React.createElement("input", { type: "submit", value: "Passwort \u00E4ndern", className: "btn btn-primary" }))))));
    }
}
PasswordReset.tokenMissingMessage = 'Token zum Passwort zurücksetzen fehlt. Bitte klicken Sie den Link aus der E-Mail zum Passwort zurücksetzen an!';
