import React, { Fragment } from 'react';
import InfoBox from '../../components/info-box';
import withCopyEdit from '../data-container';
/**
* Streetscape view/edit section
*/
const StreetscapeView = (props) => (React.createElement(Fragment, null,
    React.createElement(InfoBox, null, "Dieser Abschnitt ist noch in der Entwicklung.")));
const StreetscapeContainer = withCopyEdit(StreetscapeView);
export default StreetscapeContainer;
