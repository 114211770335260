import React, { Fragment } from 'react';
import { dataFields } from '../../config/data-fields-config';
import DataEntry from '../data-components/data-entry';
import SelectDataEntry from '../data-components/select-data-entry';
import NumericDataEntry from '../data-components/numeric-data-entry';
import withCopyEdit from '../data-container';
import Verification from '../data-components/verification';
import { useDisplayPreferences } from '../../displayPreferences-context';
import { DataEntryGroup } from '../data-components/data-entry-group';
/**
 * Use view/edit section
 */
const UseView = (props) => {
    const switchToIsDomesticMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('is_domestic');
    };
    const { darkLightTheme } = useDisplayPreferences();
    return (React.createElement(Fragment, null,
        React.createElement(DataEntryGroup, { name: "Hauptnutzung des Geb\u00E4udes", collapsed: false },
            React.createElement(SelectDataEntry, { title: dataFields.is_domestic.title, slug: "is_domestic", value: props.building.is_domestic, options: ["Wohngebäude", "gemischte Nutzung", "Nichtwohngebäude"], mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.is_domestic.tooltip }),
            React.createElement(Verification, { slug: "is_domestic", allow_verify: props.user !== undefined && props.building.is_domestic !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("is_domestic"), user_verified_as: props.user_verified.is_domestic, verified_count: props.building.verified.is_domestic }),
            React.createElement(SelectDataEntry, { title: dataFields.use_building_origin.title, slug: "use_building_origin", value: props.building.use_building_origin, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.use_building_origin.tooltip, placeholder: dataFields.use_building_origin.example, options: dataFields.use_building_origin.items }),
            React.createElement(Verification, { slug: "use_building_origin", allow_verify: props.user !== undefined && props.building.use_building_origin !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("use_building_origin"), user_verified_as: props.user_verified.use_building_origin, verified_count: props.building.verified.use_building_origin }),
            React.createElement(DataEntry, { title: dataFields.use_building_origin_text.title, tooltip: dataFields.use_building_origin_text.tooltip, slug: "use_building_origin_text", value: props.building.use_building_origin_text, mode: props.mode, 
                /* disabled={true} */
                copy: props.copy, onChange: props.onChange }),
            React.createElement(Verification, { slug: "use_building_origin_text", allow_verify: props.user !== undefined && props.building.use_building_origin_text !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("use_building_origin_text"), user_verified_as: props.user_verified.use_building_origin_text, verified_count: props.building.verified.use_building_origin_text }),
            React.createElement(SelectDataEntry, { title: dataFields.use_building_current.title, slug: "use_building_current", value: props.building.use_building_current, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.use_building_current.tooltip, placeholder: dataFields.use_building_current.example, options: dataFields.use_building_current.items }),
            React.createElement(Verification, { slug: "use_building_current", allow_verify: props.user !== undefined && props.building.use_building_current !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("use_building_current"), user_verified_as: props.user_verified.use_building_current, verified_count: props.building.verified.use_building_current }),
            React.createElement(DataEntry, { title: dataFields.use_building_current_text.title, tooltip: dataFields.use_building_current_text.tooltip, slug: "use_building_current_text", value: props.building.use_building_current_text, mode: props.mode, 
                /* disabled={true} */
                copy: props.copy, onChange: props.onChange }),
            React.createElement(Verification, { slug: "use_building_current_text", allow_verify: props.user !== undefined && props.building.use_building_current_text !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("use_building_current_text"), user_verified_as: props.user_verified.use_building_current_text, verified_count: props.building.verified.use_building_current_text })),
        React.createElement(DataEntryGroup, { name: "Geschossnutzung", collapsed: false },
            React.createElement(DataEntryGroup, { name: "Unterkellerung", collapsed: false },
                React.createElement(SelectDataEntry, { title: dataFields.basement_type.title, slug: "basement_type", value: props.building.basement_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.basement_type.tooltip, placeholder: dataFields.basement_type.example, options: dataFields.basement_type.items }),
                React.createElement(Verification, { slug: "basement_type", allow_verify: props.user !== undefined && props.building.basement_type !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("basement_type"), user_verified_as: props.user_verified.basement_type, verified_count: props.building.verified.basement_type }),
                React.createElement(NumericDataEntry, { title: dataFields.basement_percentage.title, slug: "basement_percentage", value: props.building.basement_percentage, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 0, tooltip: dataFields.basement_percentage.tooltip }),
                React.createElement(Verification, { slug: "basement_percentage", allow_verify: props.user !== undefined && props.building.basement_percentage !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("basement_percentage"), user_verified_as: props.user_verified.basement_percentage, verified_count: props.building.verified.basement_percentage }),
                React.createElement(SelectDataEntry, { title: dataFields.basement_use.title, slug: "basement_use", value: props.building.basement_use, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.basement_use.tooltip, placeholder: dataFields.basement_use.example, options: dataFields.basement_use.items }),
                React.createElement(Verification, { slug: "basement_use", allow_verify: props.user !== undefined && props.building.basement_use !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("basement_use"), user_verified_as: props.user_verified.basement_use, verified_count: props.building.verified.basement_use }),
                React.createElement(DataEntry, { title: dataFields.basement_use_source.title, tooltip: dataFields.basement_use_source.tooltip, slug: "basement_use_source", value: props.building.basement_use_source, mode: props.mode, 
                    /* disabled={true} */
                    copy: props.copy, onChange: props.onChange }),
                React.createElement(Verification, { slug: "basement_use_source", allow_verify: props.user !== undefined && props.building.basement_use_source !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("basement_use_source"), user_verified_as: props.user_verified.basement_use_source, verified_count: props.building.verified.basement_use_source })),
            React.createElement(SelectDataEntry, { title: dataFields.ground_storey_use.title, slug: "ground_storey_use", value: props.building.ground_storey_use, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.ground_storey_use.tooltip, placeholder: dataFields.ground_storey_use.example, options: dataFields.ground_storey_use.items }),
            React.createElement(Verification, { slug: "ground_storey_use", allow_verify: props.user !== undefined && props.building.ground_storey_use !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("ground_storey_use"), user_verified_as: props.user_verified.ground_storey_use, verified_count: props.building.verified.ground_storey_use }),
            React.createElement(DataEntry, { title: dataFields.ground_storey_use_source.title, tooltip: dataFields.ground_storey_use_source.tooltip, slug: "ground_storey_use_source", value: props.building.ground_storey_use_source, mode: props.mode, 
                /* disabled={true} */
                copy: props.copy, onChange: props.onChange }),
            React.createElement(Verification, { slug: "ground_storey_use_source", allow_verify: props.user !== undefined && props.building.ground_storey_use_source !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("ground_storey_use_source"), user_verified_as: props.user_verified.ground_storey_use_source, verified_count: props.building.verified.ground_storey_use_source }),
            React.createElement(SelectDataEntry, { title: dataFields.upper_storeys_use.title, slug: "upper_storeys_use", value: props.building.upper_storeys_use, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.upper_storeys_use.tooltip, placeholder: dataFields.upper_storeys_use.example, options: dataFields.upper_storeys_use.items }),
            React.createElement(Verification, { slug: "upper_storeys_use", allow_verify: props.user !== undefined && props.building.upper_storeys_use !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("upper_storeys_use"), user_verified_as: props.user_verified.upper_storeys_use, verified_count: props.building.verified.upper_storeys_use }),
            React.createElement(DataEntry, { title: dataFields.upper_storeys_use_source.title, tooltip: dataFields.upper_storeys_use_source.tooltip, slug: "upper_storeys_use_source", value: props.building.upper_storeys_use_source, mode: props.mode, 
                /* disabled={true} */
                copy: props.copy, onChange: props.onChange }),
            React.createElement(Verification, { slug: "upper_storeys_use_source", allow_verify: props.user !== undefined && props.building.upper_storeys_use_source !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("upper_storeys_use_source"), user_verified_as: props.user_verified.upper_storeys_use_source, verified_count: props.building.verified.upper_storeys_use_source })),
        React.createElement(DataEntryGroup, { name: "Nutzungsintensit\u00E4t des Geb\u00E4udes", collapsed: false },
            React.createElement(NumericDataEntry, { title: dataFields.use_number_residential_units.title, slug: "use_number_residential_units", value: props.building.use_number_residential_units, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 0, tooltip: dataFields.use_number_residential_units.tooltip }),
            React.createElement(Verification, { slug: "use_number_residential_units", allow_verify: props.user !== undefined && props.building.use_number_residential_units !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("use_number_residential_units"), user_verified_as: props.user_verified.use_number_residential_units, verified_count: props.building.verified.use_number_residential_units }),
            React.createElement(NumericDataEntry, { title: dataFields.use_number_businesses.title, slug: "use_number_businesses", value: props.building.use_number_businesses, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 0, tooltip: dataFields.use_number_businesses.tooltip }),
            React.createElement(Verification, { slug: "use_number_businesses", allow_verify: props.user !== undefined && props.building.use_number_businesses !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("use_number_businesses"), user_verified_as: props.user_verified.use_number_businesses, verified_count: props.building.verified.use_number_businesses }))));
};
const UseContainer = withCopyEdit(UseView);
export default UseContainer;
