import React from 'react';
import { Link } from 'react-router-dom';
let config = require('../../cc-config.json');
const ContributorAgreementPage = () => (React.createElement("article", null,
    React.createElement("section", { className: 'main-col' },
        React.createElement("h1", null, "Vereinbarung zur Mitwirkung"),
        React.createElement("h2", { className: 'h2' }, "Verantwortlichkeiten der Mitwirkenden"),
        React.createElement("p", null, "Wir bitten alle Mitwirkenden:"),
        React.createElement("ul", null,
            React.createElement("li", null, "sich an unseren Verhaltenskodex zu halten"),
            React.createElement("li", null, "niemals wissentlich Daten hinzuzuf\u00FCgen, die aus einer eingeschr\u00E4nkten, urheberrechtlich gesch\u00FCtzten, b\u00F6swilligen oder illegalen Quelle stammen"),
            React.createElement("li", null, "uns dabei zu helfen, eine offene Datenplattform zu schaffen, die die Entwicklung nachhaltiger, widerstandsf\u00E4higer, inklusiver und gerechter St\u00E4dte unterst\u00FCtzt und die Nutzung von Daten f\u00FCr das \u00F6ffentliche Wohl f\u00F6rdert"),
            React.createElement("li", null, "f\u00FCgen Sie Quellen hinzu, wo immer dies m\u00F6glich ist, um andere zu unterst\u00FCtzen"),
            React.createElement("li", null, "erfasste Daten, wann immer m\u00F6glich, zum Nutzen anderer zu verifizieren"),
            React.createElement("li", null, "sicherzustellen, dass unsere offenen Lizenzbedingungen in Bezug auf unsere offenen Daten und unseren offenen Code vollst\u00E4ndig befolgt werden"),
            React.createElement("li", null, "uns so wenig pers\u00F6nliche Daten wie m\u00F6glich zur Verf\u00FCgung stellen"),
            React.createElement("li", null, "die volle Verantwortung f\u00FCr die Bewertung der Zuverl\u00E4ssigkeit der Colouring Dresden Daten und ihrer Eignung f\u00FCr die beabsichtigte Nutzung zu \u00FCbernehmen (siehe auch unseren Haftungsausschluss)"),
            React.createElement("li", null, "uns Feedback zu tats\u00E4chlichen oder potenziellen Datenschutz- und Sicherheitsbedenken geben")),
        React.createElement("h2", { className: 'h2' }, "Zus\u00E4tzliche Hinweise f\u00FCr Mitwirkende"),
        React.createElement("h3", { className: 'h3' }, "Offene Daten (Open data)"),
        React.createElement("p", null, "Colouring Dresden ist ein offenes Datenprojekt. Offene Daten werden von den Mitwirkenden von Colouring Dresden unter der Open Data Commons Open Database License (https://opendatacommons.org/licenses/odbl/) lizenziert. Unter dieser Lizenz d\u00FCrfen Sie unsere Daten frei kopieren, verbreiten, \u00FCbertragen und anpassen, solange Sie Colouring Dresden und unsere Mitwirkenden als Urheber nennen. Wenn Sie unsere Daten ver\u00E4ndern oder darauf aufbauen, d\u00FCrfen Sie das Ergebnis nur unter derselben Lizenz weitergeben. Unser offener Plattformcode ist unter der GNU, General Public Licence (https://www.gnu.org/licenses/gpl-3.0.en.html) verf\u00FCgbar."),
        React.createElement("h3", { className: 'h3' }, "Wozu Sie beitragen"),
        React.createElement("p", null,
            "Colouring Dresden ist eine freie Plattform f\u00FCr den Wissensaustausch und eine offene Datenbank f\u00FCr die \u00F6ffentliche Nutzung. Sie wurde eingerichtet, um einen gesamtgesellschaftlichen Ansatz zur Verbesserung der Nachhaltigkeit, Widerstandsf\u00E4higkeit und Inklusivit\u00E4t von St\u00E4dten zu unterst\u00FCtzen. Colouring Dresden ist auch Teil des internationalen Colouring Cities Research Programme (CCRP) des Alan Turing Instituts. Das Projekt orientiert sich an den Grunds\u00E4tzen der New Urban Agenda der Vereinten Nationen, der Open Data Charter, der Datenschutz-Grundverordnung (DGSVO), den Gemini-Prinzipien, den Empfehlungen des Open Data Institute zu personenbezogenen Daten und Dateninfrastrukturen sowie an bestimmten Artikeln der Erkl\u00E4rung der Menschenrechte. Diese werden auf unserer Seite ",
            React.createElement("a", { href: "https://github.com/colouring-cities/colouring-core/issues/687", target: "_blank" }, "'Data ethics'"),
            " er\u00F6rtert, auf der wir auch das Datenethik-Canvas des Open Data Institute verwenden, um Fragen zur Verwendung und Verwaltung unserer Daten zu beantworten. Wir erfassen r\u00E4umliche Statistiken und sammeln keine Texte oder Bilder, obwohl Bilder in Zukunft integriert werden k\u00F6nnten. Die Art der von uns erfassten Geodaten kann durch Anklicken der einzelnen Datenkategorien, der Schaltfl\u00E4chen \"Info\" und der Seite \"Geb\u00E4udedatenkategorien\" eingesehen werden. Wir planen au\u00DFerdem einen \"Showcase Bereich\", der es den Nutzern der Plattform erm\u00F6glichen soll, die Art und Weise, wie die Daten von Colouring Dresden verwendet werden, mit anderen zu teilen und zu sehen."),
        React.createElement("h3", { className: 'h3' }, "Diversit\u00E4t und Inklusion"),
        React.createElement("p", null, "Wir sind sehr dankbar f\u00FCr alle konstruktiven Beitr\u00E4ge, die von unseren Mitwirkenden geliefert werden. Unsere Plattform ist f\u00FCr alle gedacht, und wir arbeiten daran, sie so inklusiv, einladend und zug\u00E4nglich wie m\u00F6glich zu gestalten. Wir respektieren die Vielfalt der Mitwirkenden und des Publikums und bem\u00FChen uns aktiv darum, diese Vielfalt zu f\u00F6rdern. Wir nutzen Farben, Crowdsourcing und nichttechnische Sprache, um die H\u00FCrden f\u00FCr das Einbringen von statistischen Informationen zu verringern und den Prozess lohnend und interessant zu gestalten. Die Vielfalt in Bezug auf Alter, Geschlecht, F\u00E4higkeiten und Fertigkeiten sowie den kulturellen Hintergrund ist auch wichtig, damit wir als Gemeinschaften unsere St\u00E4dte und Gemeinden zu integrativeren, gerechteren, nachhaltigeren und widerstandsf\u00E4higeren Orten machen k\u00F6nnen. Unser kollektives Wissen \u00FCber die Zusammensetzung, das dynamische Verhalten und die Energieleistung unserer Best\u00E4nde und dar\u00FCber, wie gut unsere Geb\u00E4ude funktionieren, ist von entscheidender Bedeutung, um die Politik dar\u00FCber zu informieren, welche Geb\u00E4ude wir wiederverwenden, abrei\u00DFen und neu bauen sollten, um die Nachr\u00FCstung zu beschleunigen und um die Best\u00E4nde als dynamische Systeme besser zu verstehen, damit sie verbessert werden k\u00F6nnen."),
        React.createElement("h3", { className: 'h3' }, "Urheberrecht, Datengenauigkeit und -qualit\u00E4t"),
        React.createElement("p", null, "Wir k\u00F6nnen keine Daten akzeptieren, die aus urheberrechtlich gesch\u00FCtzten oder eingeschr\u00E4nkten Quellen stammen (es sei denn, sie fallen unter die Fair-Use-Regelung), oder aus illegalen Quellen. Wir bitten die Mitwirkenden, die Quellen vor dem Hochladen sorgf\u00E4ltig zu pr\u00FCfen. Wir k\u00F6nnen auch keine Verantwortung f\u00FCr die Qualit\u00E4t der Datens\u00E4tze \u00FCbernehmen, da es nicht m\u00F6glich ist, jeden Dateneintrag zu \u00FCberpr\u00FCfen, und da verschiedene Nutzer je nach Verwendungszweck der Daten (z. B. Schulprojekt oder wissenschaftliche Arbeit) unterschiedliche Grade an Genauigkeit und Pr\u00E4zision ben\u00F6tigen. Unser Ziel ist es jedoch, unsere Daten so zuverl\u00E4ssig und nutzbar wie m\u00F6glich zu machen. Wir bitten daher die Teilnehmenden, Quellen anzugeben und andere Dateneintr\u00E4ge zu \u00FCberpr\u00FCfen, wo immer dies m\u00F6glich ist."),
        React.createElement("p", null, "Informationen zum Datenschutz und zur Datensicherheit finden Sie auf unserer Seite Datenschutz und Sicherheit."),
        React.createElement("div", { className: "buttons-container" },
            React.createElement(Link, { to: "sign-up.html", className: "btn btn-outline-dark" }, "Zur\u00FCck zur Registrierung")))));
export default ContributorAgreementPage;
