import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { FieldRow } from '../../data-components/field-row';
import { dataFields } from '../../../config/data-fields-config';
import SelectDataEntry from '../../data-components/select-data-entry';
import './dynamics-data-entry-rain-flood-historic-incidents.css';
import { CloseIcon } from '../../../components/icons';
import { DataTitleCopyable } from '../../data-components/data-title';
import NumericDataEntry from '../../data-components/numeric-data-entry';
export const DynamicsBuildingPaneRainFloodHistoricIncidents = ({ children, className }) => (React.createElement("div", { className: `dynamics-building-pane ${className !== null && className !== void 0 ? className : ''}` }, children));
/* functions for date and time format */
function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}
function formatDate(date) {
    return ([
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
    ].join('-'));
}
function formatTime(date) {
    return ([
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
    ].join(':'));
}
const DynamicsDataRow = ({ value = {}, onChange, disabled = false, 
/*     maxYear,
    minYear, */
mode, required = false, validateForm = false, index }) => {
    const onFieldChange = useCallback((key, val) => {
        const changedValue = Object.assign({}, value);
        changedValue[key] = val;
        onChange(changedValue);
    }, [value, onChange]);
    /*     const maxLifespan = lifespan(value.year_demolished?.max, value.year_constructed?.min);
        const minLifespan = lifespan(value.year_demolished?.min, value.year_constructed?.max); */
    /* const for current date and time */
    const currentDate = formatDate(new Date());
    const currentTime = formatTime(new Date());
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldRow, null,
            React.createElement("div", { className: 'div-incident' },
                React.createElement(SelectDataEntry, { slug: 'incident', slugModifier: index, title: dataFields.rain_flood_historic_incidents.items.incident.title, onChange: onFieldChange, value: value.incident, options: dataFields.rain_flood_historic_incidents.items.incident.items, disabled: disabled, required: required, mode: mode, tooltip: dataFields.rain_flood_historic_incidents.items.incident.tooltip })),
            React.createElement("div", { className: 'div-year' },
                React.createElement(NumericDataEntry, { title: dataFields.rain_flood_historic_incidents.items.year.title, slug: 'year', slugModifier: index, value: value.year, disabled: disabled, required: required, mode: mode, onChange: onFieldChange, step: 1, min: 0, max: 2023, tooltip: dataFields.rain_flood_historic_incidents.items.year.tooltip }))),
        React.createElement(FieldRow, null,
            React.createElement("div", { className: 'div-height_from_terrain' },
                React.createElement(NumericDataEntry, { title: dataFields.rain_flood_historic_incidents.items.height_from_terrain.title, slug: "height_from_terrain", slugModifier: index, value: value.height_from_terrain, disabled: disabled, mode: mode, required: required, 
                    /* copy={props.copy} */
                    /* tooltip={dataFields.terrain_connection_difference.tooltip} */
                    onChange: onFieldChange, step: 1, min: 0, tooltip: dataFields.rain_flood_historic_incidents.items.height_from_terrain.tooltip })),
            React.createElement("div", { className: 'div-floors_affected' },
                React.createElement(SelectDataEntry, { slug: 'floors_affected', slugModifier: index, title: dataFields.rain_flood_historic_incidents.items.floors_affected.title, onChange: onFieldChange, value: value.floors_affected, options: dataFields.rain_flood_historic_incidents.items.floors_affected.items, disabled: disabled, required: required, mode: mode, tooltip: dataFields.rain_flood_historic_incidents.items.floors_affected.tooltip })))));
};
function isValid(val) {
    if (val == undefined)
        return false;
    if (val.incident == undefined)
        return false;
    if (typeof val.year !== 'number')
        return false;
    if (val.floors_affected == undefined)
        return false;
    if (typeof val.height_from_terrain !== 'number')
        return false;
    /*     if(typeof val.year_constructed?.min !== 'number') return false;
        if(typeof val.year_constructed?.max !== 'number') return false;
    
        if(typeof val.year_demolished?.min !== 'number') return false;
        if(typeof val.year_demolished?.max !== 'number') return false;
    
        if(val.overlap_present == undefined) return false;
    
        if(val.links == undefined || val.links.length < 1) return false; */
    return true;
}
export const DynamicsDataEntryRainFloodHistoricIncidents = (props) => {
    var _a;
    const [newValue, setNewValue] = useState();
    const values = (_a = props.value) !== null && _a !== void 0 ? _a : [];
    const isEditing = props.mode === 'edit';
    const isDisabled = !isEditing || props.disabled;
    const isEdited = !_.isEmpty(newValue);
    const valid = isValid(newValue);
    const addNew = useCallback(() => {
        const val = Object.assign({}, newValue);
        setNewValue(undefined);
        props.onSaveAdd(props.slug, val);
    }, [values, newValue]);
    const edit = useCallback((id, val) => {
        const editedValues = [...values];
        editedValues.splice(id, 1, val);
        props.onChange(props.slug, editedValues);
    }, [values]);
    const remove = useCallback((id) => {
        const editedValues = [...values];
        editedValues.splice(id, 1);
        props.onChange(props.slug, editedValues);
    }, [values]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(DataTitleCopyable, { slug: props.slug, slugModifier: props.slugModifier, title: props.title, tooltip: props.tooltip, disabled: props.disabled || props.value == undefined, copy: props.copy }),
            isEditing ?
                React.createElement(React.Fragment, null,
                    React.createElement("label", null, "Bereits erfasste Eintr\u00E4ge")) :
                React.createElement("label", null, "Noch keine Eintr\u00E4ge. Zum Hinzuf\u00FCgen siehe unten."),
            React.createElement("ul", { className: "data-entry-list" },
                values.length === 0 &&
                    React.createElement("div", { className: "input-group" },
                        React.createElement("input", { className: "form-control no-entries", type: "text", value: "Noch keine Eintr\u00E4ge", disabled: true })),
                values.map((pastBuilding, id) => (React.createElement("li", { key: id },
                    React.createElement(DynamicsBuildingPaneRainFloodHistoricIncidents, null,
                        React.createElement("label", null, "Historische Betroffenheit"),
                        !isDisabled &&
                            React.createElement("button", { type: "button", className: "btn btn-outline-dark delete-record-button", title: "Eintrag l\u00F6schen", onClick: () => remove(id), "data-index": id },
                                React.createElement(CloseIcon, null)),
                        React.createElement(DynamicsDataRow, { value: pastBuilding, 
                            /* disabled={!props.editableEntries || isDisabled} */
                            disabled: true, onChange: (value) => edit(id, value), 
                            /* minYear={props.minYear}
                            maxYear={props.maxYear} */
                            mode: props.mode, required: true, index: id })))))),
            !isDisabled &&
                React.createElement("div", { className: 'new-record-section' },
                    React.createElement("h6", { className: "h6" }, "Einen neuen Eintrag hinzuf\u00FCgen"),
                    React.createElement(DynamicsBuildingPaneRainFloodHistoricIncidents, { className: 'new-record' },
                        React.createElement(DynamicsDataRow, { value: newValue, onChange: setNewValue, disabled: isDisabled, 
                            /* minYear={props.minYear}
                            maxYear={props.maxYear} */
                            mode: props.mode }),
                        React.createElement("label", null, "Bitte speichern Sie alle \u00C4nderungen bevor Sie dieses aktuell ausgew\u00E4hlte Geb\u00E4ude verlassen.  Sie werden ansonsten nicht gespeichert."),
                        React.createElement("button", { type: "button", className: "btn btn-primary btn-block add-record-button", title: "Zur Liste hinzuf\u00FCgen", onClick: addNew, disabled: !valid || props.hasEdits }, props.hasEdits ?
                            'Änderungen zuerst speichern oder verwerfen, bevor Sie einen neuen Eintrag hinzufügen' :
                            (isEdited && !valid) ?
                                'Bitte alle Felder vor dem Speichern ausfüllen' :
                                'Neuen Eintrag speichern'))))));
};
