import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth-context';
import ConfirmationModal from '../components/confirmation-modal';
import ErrorBox from '../components/error-box';
import { SpinnerIcon } from '../components/icons';
let config = require('../../cc-config.json');
export const MyAccountPage = () => {
    const { isLoading, user, userError, logout, generateApiKey, deleteAccount } = useAuth();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [error, setError] = useState(undefined);
    const handleLogout = useCallback((e) => {
        e.preventDefault();
        logout(setError);
    }, [logout]);
    const handleGenerateKey = useCallback(async (e) => {
        e.preventDefault();
        setError(undefined);
        generateApiKey(setError);
    }, [generateApiKey]);
    const handleDeleteAccount = useCallback(() => {
        setError(undefined);
        deleteAccount(setError);
    }, [deleteAccount]);
    if (!user && isLoading) {
        return (React.createElement("article", null,
            React.createElement("section", { className: "main-col" },
                React.createElement(SpinnerIcon, { spin: true }),
                " Lade Account Infos...")));
    }
    const issuesURL = config.githubURL + "/issues";
    return (React.createElement("article", null,
        React.createElement("section", { className: "main-col" },
            !isLoading && React.createElement(ErrorBox, { msg: userError }),
            !userError && (React.createElement(React.Fragment, null,
                React.createElement("h1", { className: "h1" },
                    "Willkommen, ",
                    user.username,
                    "!"),
                React.createElement("p", null,
                    "Colouring ",
                    config.cityName,
                    " wird permanent weiterentwickelt. Bitte schreiben Sie uns Ihre Ideen unter colouringdresden@ioer.de oder posten Ideen oder Probleme ",
                    ' ',
                    React.createElement("a", { href: issuesURL }, "direkt hier"),
                    "."),
                React.createElement("p", null,
                    "Zum Nachlesen finden Sie hier die ",
                    ' ',
                    React.createElement(Link, { to: "/privacy-policy.html" }, "Datenschutzerkl\u00E4rung"),
                    ", die ",
                    ' ',
                    React.createElement(Link, { to: "/contributor-agreement.html" }, "Vereinbarung zur Mitwirkung"),
                    ", den ",
                    ' ',
                    React.createElement(Link, { to: "/data-accuracy.html" }, "Haftungsausschluss"),
                    " und den ",
                    ' ',
                    React.createElement(Link, { to: "/code-of-conduct.html" }, "Verhaltenskodex f\u00FCr Mitwirkende"),
                    "."),
                React.createElement(ErrorBox, { msg: error }),
                React.createElement("form", { onSubmit: handleLogout },
                    React.createElement("div", { className: "buttons-container" },
                        React.createElement(Link, { to: "/edit/age", className: "btn btn-warning" }, "Starten"),
                        React.createElement("input", { className: "btn btn-secondary", type: "submit", value: "Ausloggen" }))),
                React.createElement("hr", null),
                React.createElement("h2", { className: "h2" }, "Meine Details"),
                React.createElement("h3", { className: "h3" }, "Benutzername"),
                React.createElement("p", null,
                    React.createElement("b", null, user.username)),
                React.createElement("h3", { className: "h3" }, "E-Mail Adresse (optional)"),
                React.createElement("p", null,
                    React.createElement("b", null, user.email || '-')),
                React.createElement("h3", { className: "h3" }, "Registriert am "),
                React.createElement("p", null,
                    React.createElement("b", null, user.registered.toString())),
                React.createElement("hr", null),
                React.createElement("h2", { className: "h2" }, "API"),
                React.createElement("p", null, "Sind Sie Softwareentwickler*in? Falls ja, k\u00F6nnte dies von Interesse f\u00FCr Sie sein:"),
                React.createElement("h3", { className: "h3" }, "API key"),
                React.createElement("p", null,
                    React.createElement("b", null, user.api_key || '-')),
                React.createElement("form", { onSubmit: handleGenerateKey, className: "form-group mb-3" },
                    React.createElement("input", { className: "btn btn-warning", type: "submit", value: "Generiere API key" })),
                React.createElement("h3", { className: "h3" }, "Open Source Code"),
                "Colouring ",
                config.cityName,
                " wird bei ",
                React.createElement("a", { href: config.githubURL }, "colouring-cities"),
                " auf Github weiterentwickelt.",
                React.createElement("hr", null),
                React.createElement("h2", { className: "h2" }, "Account"),
                React.createElement("form", { onSubmit: e => {
                        e.preventDefault();
                        setShowDeleteConfirm(true);
                    }, className: "form-group mb-3" },
                    React.createElement("input", { className: "btn btn-danger", type: "submit", value: "Account l\u00F6schen" })),
                React.createElement(ConfirmationModal, { show: showDeleteConfirm, title: "Account l\u00F6schen best\u00E4tigen", description: "Sind Sie wirklich sicher, dass Sie Ihren Account l\u00F6schen m\u00F6chten? Dies kann nicht r\u00FCckg\u00E4ngig gemacht werden!", confirmButtonText: "Account wirklich l\u00F6schen", confirmButtonClass: "btn-danger", onConfirm: () => handleDeleteAccount(), onCancel: () => setShowDeleteConfirm(false) }))))));
};
