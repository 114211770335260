import React, { useState } from 'react';
import SurveyModal from '../components/survey-modal';
let config = require('../../cc-config.json');
import { apiGet } from '../apiHelpers';
const SurveyPage = () => {
    /* const [open, setOpen] = useState(false); */
    const [showDeleteConfirm, setShowSurveyModal] = useState(false);
    async function getSurveyPopUpStatus() {
        /* setUserError(undefined); */
        /*  setIsLoading(true); */
        try {
            const user = await apiGet('/api/users/get_survey_popup_status');
            if (user.error) {
                setShowSurveyModal(false);
            }
            else {
                /* setUser(user); */
                console.log(user[0].value);
                /* convert API JSON response into boolean */
                var bool_value = user[0].value == "true" ? true : false;
                /* console.log(bool_value); */
                /* console.log(typeof(bool_value)); */
                setShowSurveyModal(bool_value);
            }
        }
        catch (err) {
            /* setUserError('Error loading user info.'); */
            setShowSurveyModal(false);
        }
    }
    ;
    return (React.createElement("article", null,
        React.createElement("section", { className: "main-col" },
            React.createElement("h1", { className: "h2" }, "Online-Umfrage"),
            React.createElement("p", null, "Test Survey"),
            React.createElement("p", null, "Willkommen bei Colouring Dresden. F\u00FCr unsere Forschung/ Evaluation w\u00FCrden wir uns \u00FCber Ihre Teilnahme an der Umfrage freuen."),
            React.createElement("form", { onSubmit: e => {
                    e.preventDefault();
                    setShowSurveyModal(true);
                }, className: "form-group mb-3" },
                React.createElement("input", { className: "btn btn-danger", type: "submit", value: "zur Umfrage" })),
            React.createElement("form", { onSubmit: e => {
                    e.preventDefault();
                    getSurveyPopUpStatus();
                }, className: "form-group mb-3" },
                React.createElement("input", { className: "btn btn-danger", type: "submit", value: "API Test" })),
            React.createElement(SurveyModal, { show: showDeleteConfirm, title: "Umfrage", description: "Willkommen bei Colouring Dresden. F\u00FCr unsere Forschung/ Evaluation w\u00FCrden wir uns \u00FCber Ihre Teilnahme an der Umfrage freuen.", confirmButtonText: "Schlie\u00DFen", confirmButtonClass: "btn-secondary", onConfirm: () => setShowSurveyModal(false), onCancel: () => setShowSurveyModal(false) }))));
};
export default SurveyPage;
