import React, { Fragment } from 'react';
import withCopyEdit from '../data-container';
import InfoBox from '../../components/info-box';
const EnergyCategoryOptions = ["A", "B", "C", "D", "E", "F", "G"];
const BreeamRatingOptions = [
    'Herausragend',
    'Exzellent',
    'Sehr gut',
    'Gut',
    'Ausreichend',
    'nicht klassifiziert'
];
/**
* Sustainability view/edit section
*/
const SustainabilityView = (props) => {
    return (React.createElement(Fragment, null,
        React.createElement(InfoBox, null, "Dieser Abschnitt ist noch in der Entwicklung.")));
};
const SustainabilityContainer = withCopyEdit(SustainabilityView);
export default SustainabilityContainer;
