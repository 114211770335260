import React from 'react';
import { Link } from 'react-router-dom';
let config = require('../../cc-config.json');
const PrivacyPolicyPage = () => (React.createElement("article", null,
    React.createElement("section", { className: 'main-col' },
        React.createElement("h1", { className: 'h1' }, "Datenschutz & Sicherheit"),
        React.createElement("h2", { className: 'h2' }, "Datenschutzerkl\u00E4rung der Colouring Dresden Plattform in Bezug auf personenbezogene Daten"),
        React.createElement("p", null, "In dieser Datenschutzerkl\u00E4rung wird erl\u00E4utert, wie Colouring Dresden die personenbezogenen Daten verwendet, die wir bei der Nutzung unserer Website von Ihnen erheben. Colouring Dresden ist ein Forschungsprojekt. Colouring Dresden ist f\u00FCr Datenschutzzwecke bei der Datenschutzbeh\u00F6rde des Leibniz-Instituts f\u00FCr \u00F6kologische Raumentwicklung e.V. registriert."),
        React.createElement("h2", { className: 'h2' }, "Welche Daten sammeln wir?"),
        React.createElement("p", null, "Colouring Dresden sammelt die folgenden pers\u00F6nlichen Daten:"),
        React.createElement("p", null, "Einen Benutzernamen und optional zum Zwecke des Zur\u00FCcksetzens des Passwort eine E-Mail Adresse. Wir empfehlen Ihnen, nicht Ihren tats\u00E4chlichen Namen f\u00FCr Ihren Benutzernamen zu verwenden. Wir erheben auch Ihr Passwort, das als kryptographischer Hash gespeichert wird, der nur f\u00FCr Colouring Dresden gilt."),
        React.createElement("h2", { className: 'h2' }, "Wie sammeln wir Ihre Daten?"),
        React.createElement("p", null, "Wenn Sie sich auf der Website registrieren und die Nutzungsbedingungen einschlie\u00DFlich dieser Datenschutzrichtlinie akzeptieren, stellen Sie Colouring Dresden die oben genannten pers\u00F6nlichen Daten zur Verf\u00FCgung."),
        React.createElement("h2", { className: 'h2' }, "F\u00FCr welche Zwecke verwenden wir Ihre Daten?"),
        React.createElement("p", null, "Colouring Dresden verwendet Ihre pers\u00F6nlichen Daten, um Ihnen den Zugang zum Colouring Dresden Projekt zu erm\u00F6glichen und um Ihnen eine personalisierte Benutzererfahrung zu bieten, wenn Sie eingeloggt sind. Wir werden Ihre pers\u00F6nlichen Daten (wie z.B. Ihre E-Mail-Adresse) nicht an Dritte weitergeben oder f\u00FCr andere Zwecke als das Colouring Dresden Projekt verwenden."),
        React.createElement("p", null,
            "Wenn Sie eine Passwort-R\u00FCcksetzung beantragen, wird eine automatisierte E-Mail mit Hilfe von  ",
            React.createElement("a", { href: "https://www.cleverreach.com/de-de/", target: "_blank" }, "CleverReach"),
            " verschickt, die die E-Mail verarbeitet, um sie an Ihre E-Mail-Adresse zu senden. CleverReach speichert personenbezogene Daten, die sie im Auftrag von Colouring Dresden verarbeiten, so lange, wie es f\u00FCr die Bereitstellung der E-Mail-Dienste erforderlich ist. CleverReach bewahrt die personenbezogenen Daten so lange auf, wie es f\u00FCr die Erf\u00FCllung ihrer rechtlichen Verpflichtungen, die Beilegung von Streitigkeiten und die Durchsetzung ihrer Vereinbarungen erforderlich ist."),
        React.createElement("h2", { className: 'h2' }, "Was ist die Rechtsgrundlage f\u00FCr die Verarbeitung Ihrer Daten?"),
        React.createElement("p", null,
            "Die DSGVO verlangt, dass bestimmte Bedingungen erf\u00FCllt sein m\u00FCssen, bevor wir Ihre Daten auf die in dieser Erkl\u00E4rung beschriebene Weise verwenden d\u00FCrfen, einschlie\u00DFlich einer \"Rechtsgrundlage\" f\u00FCr die Verarbeitung. Colouring Dresden, als Forschungsprojekt, verarbeitet Ihre personenbezogenen Daten in Verfolgung seiner berechtigten Interessen. Die Rechtsgrundlage hierf\u00FCr ist ",
            React.createElement("a", { href: "https://dsgvo-gesetz.de/art-6-dsgvo/", target: "_blank" }, "Art. 6 Abs. 1 DSGVO"),
            "."),
        React.createElement("h2", { className: 'h2' }, "Wie speichern wir Ihre Daten?"),
        React.createElement("p", null, "Colouring Dresden speichert alle Daten auf einem Server in Deutschland."),
        React.createElement("h2", { className: 'h2' }, "Wie verwenden wir Cookies?"),
        React.createElement("p", null, "Colouring Dresden verwendet Cookies nur, um die Benutzerfreundlichkeit der Website zu verbessern, z.B. verwenden wir Cookies, um Sie angemeldet zu halten. Wir verwenden keine Cookies f\u00FCr Marketing- oder Werbezwecke."),
        React.createElement("h2", { className: 'h2' }, "Welche Rechte haben Sie in Bezug auf den Datenschutz?"),
        React.createElement("p", null, "Gem\u00E4\u00DF der gesetzlichen Vorgaben zum Datenschutz haben Sie bestimmte individuelle Rechte in Bezug auf die von uns gespeicherten personenbezogenen Daten. F\u00FCr Forschungszwecke, bei denen solche individuellen Rechte die Forschungsergebnisse ernsthaft beeintr\u00E4chtigen w\u00FCrden, sind diese Rechte eingeschr\u00E4nkt. Unter bestimmten Bedingungen haben Sie jedoch die folgenden Rechte in Bezug auf Ihre personenbezogenen Daten:"),
        React.createElement("ul", null,
            React.createElement("li", null, "Ein Recht auf Zugang zu den von uns \u00FCber Sie gespeicherten personenbezogenen Daten."),
            React.createElement("li", null, "Das Recht, von uns die Berichtigung unrichtiger personenbezogener Daten zu verlangen, die wir \u00FCber Sie gespeichert haben"),
            React.createElement("li", null, "Das Recht, von uns zu verlangen, dass wir die \u00FCber Sie gespeicherten personenbezogenen Daten l\u00F6schen. Dieses Recht gilt nur, wenn wir die personenbezogenen Daten nicht mehr ben\u00F6tigen, um den Zweck zu erf\u00FCllen, f\u00FCr den wir sie erhoben haben."),
            React.createElement("li", null, "Ein Recht auf Einschr\u00E4nkung der Verarbeitung der von uns \u00FCber Sie gespeicherten personenbezogenen Daten. Dieses Recht gilt nur, wenn Sie z. B. die Richtigkeit der von uns gespeicherten personenbezogenen Daten bestreiten oder wenn Sie das Recht h\u00E4tten, von uns die L\u00F6schung der personenbezogenen Daten zu verlangen, stattdessen aber eine Einschr\u00E4nkung der Verarbeitung w\u00FCnschen, oder wenn wir die personenbezogenen Daten nicht mehr zur Erf\u00FCllung des Zwecks, f\u00FCr den wir sie erhoben haben, verwenden m\u00FCssen, die Daten aber f\u00FCr die Bearbeitung von Rechtsanspr\u00FCchen ben\u00F6tigen."),
            React.createElement("li", null, "Sie haben das Recht, personenbezogene Daten, die Sie uns zur Verf\u00FCgung gestellt haben, in einem strukturierten, g\u00E4ngigen und maschinenlesbaren Format zu erhalten. Sie haben auch das Recht, von uns zu verlangen, dass diese personenbezogenen Daten an eine andere Organisation \u00FCbermittelt werden."),
            React.createElement("li", null, "Sie haben das Recht, gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten durch uns Widerspruch einzulegen."),
            React.createElement("li", null, "Sie haben das Recht, Ihre Zustimmung zu widerrufen, wenn wir uns auf diese Zustimmung zur Verwendung Ihrer personenbezogenen Daten st\u00FCtzen."),
            React.createElement("li", null, "Das Recht, von uns zu verlangen, dass wir Informationen \u00FCber Sie nicht in einer Weise verwenden, die es Computern erm\u00F6glicht, Entscheidungen \u00FCber Sie zu treffen, und uns zu bitten, damit aufzuh\u00F6ren.")),
        React.createElement("p", null, "Es ist wichtig zu verstehen, dass das Ausma\u00DF, in dem diese Rechte f\u00FCr die Forschung gelten, unterschiedlich ist und dass Ihre Rechte unter bestimmten Umst\u00E4nden eingeschr\u00E4nkt sein k\u00F6nnen. Wenn Sie uns (\u00FCber die unten angegebenen Kontaktdaten) mitteilen, dass Sie eines der oben genannten Rechte aus\u00FCben m\u00F6chten, und es f\u00FCr notwendig erachtet wird, die Einhaltung eines Ihrer individuellen Rechte zu verweigern, werden Sie innerhalb eines Monats \u00FCber die Entscheidung informiert und haben au\u00DFerdem das Recht, sich bei der zust\u00E4ndigen Datenschutz-Aufsichtsbeh\u00F6rde \u00FCber unsere Entscheidung zu beschweren."),
        React.createElement("p", null, "Bitte beachten Sie auch, dass wir einem Antrag auf Aus\u00FCbung Ihrer Rechte nur w\u00E4hrend des Zeitraums nachkommen k\u00F6nnen, in dem wir personenbezogene Daten \u00FCber Sie gespeichert haben. Wenn diese Informationen unwiderruflich anonymisiert wurden und Teil des Forschungsdatensatzes geworden sind, ist es uns nicht mehr m\u00F6glich, auf Ihre pers\u00F6nlichen Daten zuzugreifen."),
        React.createElement("h2", { className: 'h2' }, "Wo finde ich \u00C4nderungen zu dieser Datenschutzerkl\u00E4rung?"),
        React.createElement("p", null, "\u00C4nderungen an dieser Datenschutzerkl\u00E4rung werden auf der Website von Colouring Dresden (https://colouring.dresden.ioer.info) bekannt gegeben. Diese Datenschutzrichtlinie wurde zuletzt am 02. M\u00E4rz 2023 aktualisiert."),
        React.createElement("h2", { className: 'h2' }, "An wen kann ich mich mit Fragen wenden?"),
        React.createElement("p", null, "Mit Fragen und Anregungen zu Inhalt und technischer Umsetzung der Plattform Colouring Dresden wenden Sie sich bitte an das Projektteam unter colouringdresden@ioer.de oder direkt an Hr. Robert Hecht, 0351/4679248, E-Mail: R.Hecht@ioer.de"),
        React.createElement("p", null, "Verantwortliche Stelle im Sinne der europ\u00E4ischen Datenschutz-Grundverordnung f\u00FCr unsere Datenverarbeitung ist das Leibniz-Institut f\u00FCr \u00F6kologische Raumentwicklung e.V. (I\u00D6R), Weberplatz 1, 01217 Dresden, Tel. 0351/46790, Fax 0351/4679212, E-Mail: info@ioer.de, Website: www.ioer.de; Direktor: Univ.-Prof. Dr. Marc Wolfram."),
        React.createElement("p", null, "Mit Fragen zum Datenschutz und der Wahrnehmung s\u00E4mtlicher Rechte diesbez\u00FCglich, wenden Sie sich bitte an die Datenschutzbeauftragten des I\u00D6R: DID Dresdner Institut f\u00FCr Datenschutz, Herr Prof. Dr. Ralph Wagner, Hospitalstr. 4, 01097 Dresden, 0351/6557220, r.wagner@dids.de oder  an den Datenschutzkoordinator des I\u00D6R, J\u00F6rg Hennersdorf, datenschutz@ioer.de."),
        React.createElement("p", null, "Sollten wir nicht in der Lage sein, Ihre Bedenken bez\u00FCglich der Art und Weise, wie wir Ihre Daten verwenden, angemessen zu ber\u00FCcksichtigen, haben Sie gem\u00E4\u00DF Art. 77 DSGVO das Recht, eine formelle Beschwerde bei der zust\u00E4ndigen Datenschutz-Aufsichtsbeh\u00F6rde einzureichen. Ausf\u00FChrliche Informationen finden Sie auf der Website https://www.saechsdsb.de/."),
        React.createElement("h2", { className: 'h2' }, "Weitere Informationen zum Datenschutz und zur Sicherheit"),
        React.createElement("p", null, "Bitte beachten Sie, dass Sie, wenn Sie einen Beitrag zu Colouring Dresden leisten, eine permanente, \u00F6ffentliche Aufzeichnung aller von Ihnen hinzugef\u00FCgten, entfernten oder ge\u00E4nderten Daten erstellen. Die Datenbank speichert den Benutzernamen und die ID des Benutzers, der die \u00C4nderung vornimmt, sowie die Uhrzeit und das Datum der \u00C4nderung. Alle diese Informationen werden auch \u00FCber die Website und durch Massen-Downloads der Bearbeitungshistorie \u00F6ffentlich zug\u00E4nglich gemacht. Die Benutzernamen derjenigen, die die meisten \u00C4nderungen vorgenommen haben, werden auch in unsere Ranglisten aufgenommen."),
        React.createElement("p", null, "Bitte beachten Sie auch, dass Sie, wenn Sie zu Colouring Dresden beitragen, Ihre Beitr\u00E4ge als offene Daten zur Verf\u00FCgung stellen, die von jedermann kopiert, verbreitet, \u00FCbertragen und entsprechend der Lizenz angepasst und nach eigenem Ermessen genutzt werden k\u00F6nnen. Obwohl wir jeden Datentyp sorgf\u00E4ltig pr\u00FCfen, sind wir f\u00FCr Verbesserungsvorschl\u00E4ge dankbar, um die Privatsph\u00E4re und Sicherheit der Geb\u00E4udenutzer zu sch\u00FCtzen."),
        React.createElement("p", null,
            "Die Fortschritte bei den Colouring Dresden-Funktionen, die speziell auf ethische Fragen, einschlie\u00DFlich der Sicherheit und des Datenschutzes, abzielen, k\u00F6nnen auf unserer GitHub-Seite ",
            React.createElement("a", { href: "https://github.com/colouring-cities/colouring-core/issues/687" }, "#687"),
            " verfolgt und kommentiert werden."),
        React.createElement("div", { className: "buttons-container" },
            React.createElement(Link, { to: "sign-up.html", className: "btn btn-outline-dark" }, "Zur\u00FCck zur Registrierung")))));
export default PrivacyPolicyPage;
