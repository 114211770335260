import React from 'react';
import { dateReviver } from '../../helpers';
import { apiGet } from '../apiHelpers';
export default class DataExtracts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            extracts: undefined,
            latestExtract: undefined,
            previousExtracts: undefined
        };
    }
    async componentDidMount() {
        let data = await apiGet('/api/extracts', { jsonReviver: dateReviver });
        const extracts = data.extracts;
        this.setState({ extracts: extracts, latestExtract: extracts[0], previousExtracts: extracts.slice(1) });
    }
    render() {
        return (React.createElement("article", null,
            React.createElement("section", { className: "main-col" },
                React.createElement("h1", { className: "h2" }, "W\u00F6chentliche Datenextrakte (als offene Daten)"),
                React.createElement("p", null, "Die bereits erzeugten Datenextrakte der auf der Plattform \"Colouring Dresden\" erfassten Geb\u00E4udemerkmale sind \u00FCber die unten stehenden Links herunterladbar."),
                React.createElement("p", null,
                    "Die erfassten Daten von Colouring Dresden sind offene Daten und stehen unter der offenen Lizenz ",
                    React.createElement("a", { href: "http://opendatacommons.org/licenses/odbl/", target: "_blank" }, "Open Data Commons Open Database License"),
                    " (ODbL) durch die Colouring Dresden Mitwirkenden."),
                React.createElement("p", null, "Es steht Ihnen frei, die Daten zu kopieren, zu teilen und anzupassen, solange Sie Colouring Dresden und Mitwirkende nennen. Die Daten d\u00FCrfen nur unter der selben Lizenz verbreitet werden."),
                React.createElement("p", null, "W\u00E4hlen Sie einen der unten stehenden Links aus, um die Daten herunterzuladen."),
                this.state.extracts == undefined ?
                    React.createElement("p", null, "Loading extracts...") :
                    (this.state.extracts.length === 0 ?
                        React.createElement("p", null, "Noch keine Datenextrakte verf\u00FCgbar.") :
                        null),
                this.state.latestExtract != undefined ?
                    React.createElement("div", null,
                        React.createElement("h1", { className: "h3" }, "Letztes Extrakt"),
                        React.createElement(ExtractDownloadLink, Object.assign({}, this.state.latestExtract))) :
                    null,
                this.state.previousExtracts && this.state.previousExtracts.length > 0 ?
                    (React.createElement("div", null,
                        React.createElement("h1", { className: "h3" }, "\u00C4ltere Extrakte"),
                        React.createElement("ul", null, this.state.previousExtracts.map(e => React.createElement("li", null,
                            React.createElement(ExtractDownloadLink, Object.assign({}, e))))))) :
                    null)));
    }
}
const ExtractDownloadLink = (props) => (React.createElement("p", null,
    React.createElement("a", { href: props.download_path },
        "Extrahiert am ",
        props.extracted_on.toDateString())));
